/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Head from 'next/head'
import useDynamicScript from '@hooks/useDynamicScript'
import useExtractContentAndScripts from '@hooks/useExtractContentAndScripts'

const socialEmbedComponent = (props) => {
  const { socialContent } = props

  // extract the content and script strings from the socialContent prop
  const { content, scriptStrings } = useExtractContentAndScripts(socialContent)

  // load the scripts one at a time using state to keep track of the index of the script to load
  const [scriptIndex, setScriptIndex] = useState(0)
  // each raw script text string in scriptStrings is passed into the useDynamicScript
  // useDynamicScript is responsible for parsing text string and converting it
  // to a script element and injecting the script into the DOM to be executed
  const { loading, loaded } = useDynamicScript(scriptStrings[scriptIndex])

  // once the script is loaded, the state is updated to load the next script
  // this is done by incrementing the scriptIndex state
  useEffect(() => {
    if (!loading && loaded && scriptIndex < scriptStrings.length - 1) {
      setScriptIndex(prevIndex => prevIndex + 1)
    }
  }, [loading, loaded, scriptIndex, scriptStrings])

  return (
    <div>
      <Head>
        <script async src="//www.instagram.com/embed.js" />
        <script async src="https://platform.twitter.com/widgets.js" charSet="utf-8" />
      </Head>
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  )
}

socialEmbedComponent.propTypes = {
  socialContent: PropTypes.string,
}

const SocialEmbed = (props) => {
  return socialEmbedComponent(props)
}

SocialEmbed.propTypes = {
  socialContent: PropTypes.string,
}

export default SocialEmbed

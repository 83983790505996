// converts raw header/cookie value into an object w/ key-value pairs
// e.g.
//   - input: flag-a=myvariant,flag-b=anothervariant
//   - output: { 'flag-a': 'myvariant', 'flag-b': 'anothervariant' }
export const deserializeFlagValues = (rawFlagValues) => {
  const flagValues = (rawFlagValues != null && rawFlagValues.length > 0 ? rawFlagValues.split(',').map(c => c.split('=')) : [])
    .reduce((acc, val) => {
      const [k, v] = val
      acc[k] = v
      return acc
    }, {})
  return flagValues
}

// converts flag values object into serialized form
// (comma-separated key=value string, sorted alphabetically)
// e.g.
//   - input: { 'flag-a': 'myvariant', 'flag-b': 'anothervariant' }
//   - output: flag-a=myvariant,flag-b=anothervariant
export const serializeFlagValues = (flagValues) => {
  const flagKeys = Object.keys(flagValues)
  flagKeys.sort()
  const serializedFlagValues = flagKeys.map(k => `${k}=${flagValues[k]}`).join(',')
  return serializedFlagValues
}

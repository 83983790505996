/* eslint-disable camelcase */
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import defaults from '@config/defaults'
import { useStyles, EmbedInlineStoryRoot } from '@components/embed-inline-story/embed-inline-story.styles'
import CtflAsset from '@components/ctfl-asset'
import { Image } from '@components/atoms'
import { ArticleHeroRoot } from '@components/story-hero/article-hero.styles'

const EmbedInlineStory = (props) => {
  const {
    headline,
    image,
    slug,
  } = props

  const storyUrl = slug ? `${defaults.baseUrl}story/${slug}` : null
  const s = useStyles()


  const logo = '/images/logo-blue.png'

  return (
    <EmbedInlineStoryRoot>
      <a href={storyUrl}>
        {(image && (
          <ArticleHeroRoot maxwidth={image.width}>
            <Box mx={[-2, -3, -4, 0]}>
              <CtflAsset {...image} hideDescription />
            </Box>
          </ArticleHeroRoot>
        ))}
        {(!image && (
          <Box p={{ xs: 1, sm: 2, md: 3 }}>
            <Image src={logo} alt="PGA logo" width="100%" />
          </Box>
        ))}
        <Typography variant="h6" className={s.embedHeadline}>{headline}</Typography>
      </a>
    </EmbedInlineStoryRoot>
  )
}

EmbedInlineStory.propTypes = {
  slug: PropTypes.string,
  headline: PropTypes.string,
  image: PropTypes.shape(),
}

export default EmbedInlineStory

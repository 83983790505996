import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.offwhite.main,
    zIndex: 20,
  },
  container: {
    height: '30px',
  },
  link: {
    ...theme.typography.overline,
    fontSize: '.75rem',
    fontWeight: 400,
    lineHeight: 0,
    display: 'flex',
    alignItems: 'center',
    marginLeft: '1.5625rem',
    color: theme.palette.common.black,
    '&:hover,&:active,&:focus': {
      color: theme.palette.common.black,
      textDecoration: 'underline',
      textUnderlineOffset: '3px',
    },
  },
  search: {
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    padding: '0',
    '& .MuiSvgIcon-root': {
      width: 15,
      height: 15,
      color: theme.palette.primary.dark,
      marginRight: '.25rem',
    },
  },
  thumbnail: {
    marginRight: '.25rem',
  },
}))

export default useStyles

import { useEffect, useState } from 'react'

const useDynamicScript = (scriptString) => {
  const [loading, setLoading] = useState(true)
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    setLoading(true)

    const parser = new DOMParser()
    const scriptTag = parser.parseFromString(scriptString, 'text/html').querySelector('script')

    if (scriptTag) {
      const scriptElement = document.createElement('script')

      Array.from(scriptTag.attributes).forEach((attr) => {
        scriptElement.setAttribute(attr.name, attr.value)
      })

      if (scriptTag.textContent.trim()) {
        scriptElement.textContent = scriptTag.textContent
      }

      if (scriptElement.src) {
        scriptElement.onload = () => {
          setLoading(false)
          setLoaded(true)
        }
      } else {
        setLoading(false)
        setLoaded(true)
      }

      document.body.appendChild(scriptElement)

      return () => {
        if (scriptElement.parentNode === document.body) {
          document.body.removeChild(scriptElement)
        }
      }
    }

    return () => {} // Return an empty function to satisfy the linter in all cases
  }, [scriptString])

  return { loading, loaded }
}

export default useDynamicScript

import React from 'react'
import PropTypes from 'prop-types'
import { IconArrow } from '@components/atoms/icons'

const SliderArrow = (props) => {
  const {
    className, style, color, onClick,
  } = props
  return (
    <div
      className={className}
      style={{ ...style }}
      role="presentation"
      onClick={onClick}
    >
      <IconArrow viewBox="0 0 32 7" style={{ fontSize: '2rem' }} stroke={color} />
    </div>
  )
}

SliderArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string,
  onClick: PropTypes.func,
}

export default SliderArrow

import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

const ErrorStatusCode = (props) => {
  const { statusCode, errorMessage } = props

  let errorName
  switch (statusCode) {
  case 403:
    errorName = 'Forbidden'
    break
  case 404:
    errorName = 'Not Found'
    break
  case 500:
    errorName = 'Internal Server Error'
    break
  default:
    errorName = 'Error'
    break
  }

  return (
    <Box py={[8, 10, 12]} textAlign="center">
      <Typography variant="h4" component="h1" align="center">
        {`${statusCode} - ${errorName}`}
      </Typography>
      <Typography paragraph align="center">
        {errorMessage}
        {!errorMessage && statusCode === 403 && 'You are not authorized to view this page.'}
        {!errorMessage && statusCode === 404 && 'The page you requested can\'t be found.'}
        {!errorMessage && statusCode === 500 && 'An unexpected error has occurred'}
      </Typography>
      <img src="/images/not-found.png" alt={statusCode} width={300} />
    </Box>
  )
}

ErrorStatusCode.propTypes = {
  statusCode: PropTypes.number,
  errorMessage: PropTypes.string,
}

ErrorStatusCode.defaultProps = {}

export default ErrorStatusCode

import fetch from 'isomorphic-unfetch'
import { ApolloClient, ApolloLink, HttpLink } from '@apollo/client'
import { InMemoryCache } from '@apollo/client/cache'
import { setContext } from '@apollo/client/link/context'

const contentfulPossibleTypes = {
  Entry: ['PageHome', 'Slide', 'Story', 'Event', 'NavMain', 'Tour', 'EventsLanding', 'CoursesLanding', 'RecommendedArticles', 'RelatedArticles', 'StoriesPage', 'Tag', 'Person', 'TableRow', 'Table', 'PullQuote'],
  PageHomeFeaturedStoriesItem: ['Event', 'Slide', 'Story'],
}

let apolloClient = null

if (!process.browser) {
  global.fetch = fetch
}

function create(initialState) {
  const { preview } = initialState

  const loggerLink = new ApolloLink((operation, forward) => {
    const { operationName } = operation
    /* eslint-disable-next-line no-console */
    console.log(`Initiating GraphQL Request (${operation.getContext().clientName || 'contentful'} - ${operationName})`)
    const startTime = new Date().getTime()
    return forward(operation).map((result) => {
      const ellapsed = new Date().getTime() - startTime
      /* eslint-disable-next-line no-console */
      console.log(`GraphQL Request (${operation.getContext().clientName || 'contentful'} - ${operationName}) took ${ellapsed}ms`)
      return result
    })
  })

  const deliveryToken = process.env.CTFL_DELIVERY_TOKEN
  const previewToken = process.env.CTFL_PREVIEW_TOKEN
  const ctflUrl = process.env.CTFL_URL
  const ctflEnv = preview ? 'master' : process.env.CTFL_ENV

  const contentfulLink = new HttpLink({
    uri: ctflUrl + ctflEnv,
    credentials: 'same-origin',
    fetch: !process.browser && fetch,
    headers: {
      Authorization: `Bearer ${preview ? previewToken : deliveryToken}`,
    },
  })

  const withRecaptcha = setContext((_, ctx) => {
    if (ctx.clientName === 'coach-tools') {
      const hasRecaptchaToken = ctx.recaptchaToken != null
      return {
        headers: {
          ...(hasRecaptchaToken && { 'X-PGA-RECAPTCHA': ctx.recaptchaToken }),
        },
      }
    }
    return {}
  })

  const coachToolsLink = withRecaptcha.concat(new HttpLink({
    uri: process.env.COACH_TOOLS_GRAPHQL_ENDPOINT,
    headers: {
      'X-Client-Version': process.env.APP_VERSION,
      'X-Client-Name': 'pga-com',
    },
    credentials: 'same-origin',
    fetch: !process.browser && fetch,
  }))

  const pgaApiLink = new HttpLink({
    uri: process.env.GRAPH_URL,
    credentials: 'same-origin',
    fetch: !process.browser && fetch,
  })

  const composedLink = ApolloLink.from([
    loggerLink,
    ApolloLink.split(
      operation => operation.getContext().clientName === 'coach-tools',
      coachToolsLink,
      ApolloLink.split(
        operation => ['pga-datafeed', 'pga-algolia'].includes(operation.getContext().clientName),
        pgaApiLink,
        contentfulLink,
      ),
    ),
  ])

  const client = new ApolloClient({
    connectToDevTools: true,
    ssrMode: !process.browser,
    link: composedLink,
    cache: new InMemoryCache({ possibleTypes: { ...contentfulPossibleTypes } }).restore(initialState || {}),
  })

  if (initialState) {
    client.cache.restore(initialState)
  }

  return client
}

export default function initApollo(initialState) {
  if (!process.browser) {
    return create(initialState)
  }

  if (!apolloClient) {
    apolloClient = create(initialState)
  }

  return apolloClient
}

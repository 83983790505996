import React from 'react'
import PropTypes from 'prop-types'
import { getDataFromTree } from '@apollo/client/react/ssr'
import { isPreviewMode } from '@utils'
import initApollo from './apollo-init'

// eslint-disable-next-line arrow-parens
export default App => class Apollo extends React.Component {
  static displayName = 'withApollo(App)'

  static propTypes = {
    apolloState: PropTypes.object,
  }

  static async getInitialProps(props) {
    const { Component, router, ctx } = props
    const { req } = ctx
    const preview = isPreviewMode(req)
    let appProps = {}

    if (App.getInitialProps) {
      appProps = await App.getInitialProps(props)
    }

    // Run all GraphQL queries in the component tree
    // and extract the resulting data
    const apollo = initApollo({ preview, reqHeaders: req && req.headers })
    if (!process.browser) {
      try {
        // Run all GraphQL queries
        await getDataFromTree(
          <App
            {...appProps}
            Component={Component}
            router={router}
            apolloClient={apollo}
          />,
        )
      } catch (error) {
        // Prevent Apollo Client GraphQL errors from crashing SSR.
        // Handle them in components via the data.error prop:
        // https://www.apollographql.com/docs/react/api/react-apollo.html#graphql-query-data-error
        const stringifiedError = JSON.stringify(error)
        // eslint-disable-next-line no-console
        console.error('Error while running `getDataFromTree`', stringifiedError !== '{}' ? stringifiedError : error)
      }
    }

    const apolloState = apollo.cache.extract()
    apolloState.preview = preview

    return {
      ...appProps,
      apolloState,
      preview,
    }
  }

  constructor(props) {
    super(props)
    this.apolloClient = initApollo(props.apolloState)
  }

  render() {
    return <App {...this.props} apolloClient={this.apolloClient} />
  }
}

/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
/* eslint-disable func-names */
/* eslint-disable no-extend-native */
import React from 'react'
import PropTypes from 'prop-types'
import App from 'next/app'
import Router from 'next/router'
import Head from 'next/head'
import { ApolloProvider } from '@apollo/client'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/core/styles'
import { DefaultSeo } from 'next-seo'
import { RaygunActivate } from '@utils/tracing/raygun'
import { withFeatureFlagProvider } from '@lib/feature-flags'
import { parseCookies, isPreviewMode, redirects } from '@utils'
import { compose } from 'ramda'
import { JsonLd } from '@components/atoms'
import * as Amplitude from '@amplitude/analytics-browser'
import withApolloClient from '../src/lib/apollo-client'
import Shell from '../src/components/shell'
import themeMUI from '../src/config/mui-theme'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

import SEO from '../next-seo.config'

// Polyfill for endsWith IE11
if (!String.prototype.endsWith) {
  String.prototype.endsWith = function (search, this_len) {
    if (this_len === undefined || this_len > this.length) {
      this_len = this.length
    }
    return this.substring(this_len - search.length, this_len) === search
  }
}

class PGAApp extends App {
  static async getInitialProps({ Component, ctx }) {
    const { req = {}, res } = ctx
    const cookies = req && parseCookies(req)
    const preview = req && isPreviewMode(req)
    let pageProps = {}

    if (!process.browser) {
      /* eslint-disable-next-line no-console */
      console.info('Request URL:', req?.url)
    }

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx)

      let { url } = req
      if (url && url.length > 1) {
        if (url && url.includes('?')) url = url.substring(0, url.indexOf('?'))
        if (url.endsWith('/')) url = url.slice(0, -1)
        const route = await redirects(url, req.headers.referer)
        if (route.redirect) {
          if (res) {
            res.writeHead(301, {
              Location: route.redirect,
            })
            res.end()
          } else {
            Router.push(route.redirect)
          }
          return {}
        }
      }


      if (pageProps.query) {
        pageProps.query.preview = preview
      } else {
        pageProps.query = {
          preview,
        }
      }
    }
    return { pageProps, cookies }
  }

  render() {
    const {
      Component, pageProps, apolloClient, cookies,
    } = this.props
    const embed = pageProps?.query?.embed

    Amplitude.init(process.env.AMPLITUDE_API_KEY, {
      // This allows Amplitude to send events asynchronously, including after a domain redirect.
      transport: 'beacon',
      // To make sure the event will be scheduled right away.
      flushIntervalMillis: 0,
      flushQueueSize: 1,
      defaultTracking: {
        attribution: false,
        pageViews: false,
        sessions: false,
        formInteractions: false,
        fileDownloads: false,
      },
    })

    return (
      <React.Fragment>
        <Head>
          <meta name="theme-color" content={themeMUI.palette.secondary.main} />
        </Head>
        <DefaultSeo {...SEO} />
        <JsonLd {...{
          '@context': 'https://schema.org/',
          '@graph': [
            {
              '@type': 'MobileApplication',
              name: 'MyPGA - Connect, Learn & Play Golf',
              description: "Swing into fun with PGA of America! Whether you're perfecting your putt with a PGA Coach, cheering on your kid’s PGA Junior League season, or finding your next golf group, MyPGA is your guide to the game.",
              image: 'https://is1-ssl.mzstatic.com/image/thumb/Purple122/v4/13/80/70/1380701a-2f79-2f87-cb26-ec19a479c337/AppIcon-prod-1x_U007emarketing-0-7-0-85-220.png/460x0w.webp',
              operatingSystem: 'iOS',
              offers: {
                '@type': 'Offer',
                availability: 'https://schema.org/InStock',
                url: 'https://apps.apple.com/us/app/mypga/id1554350218',
              },
              applicationCategory: 'https://schema.org/SportsApplication',
            },
            {
              '@type': 'MobileApplication',
              name: 'MyPGA - Connect, Learn & Play Golf',
              description: "Swing into fun with PGA of America! Whether you're perfecting your putt with a PGA Coach, cheering on your kid’s PGA Junior League season, or finding your next golf group, MyPGA is your guide to the game.",
              image: 'https://play-lh.googleusercontent.com/MNsRysu94EVNi4_4f-BG7MvFwuqoCdIhtsP3FeEXcuLrbOOMHwvrJYLlVkqiQLVr_Ofs=w480-h960-rw',
              operatingSystem: 'Android',
              offers: {
                '@type': 'Offer',
                availability: 'https://schema.org/InStock',
                url: 'https://play.google.com/store/apps/details?id=com.pga.MyPGA.production',
              },
              applicationCategory: 'https://schema.org/SportsApplication',
            },
            {
              '@type': 'MobileApplication',
              name: 'PGA Coach',
              description: 'Become the Modern Golf Coach with PGA Coach! Created by the PGA of America and built exclusively for PGA Coaches to engage with golfers through an innovative Coaching Platform. I knew it was for me when the first student scheduled through the booking link and I received an immediate confirmation. — Mark Marshall',
              image: 'https://is1-ssl.mzstatic.com/image/thumb/Purple221/v4/29/64/1e/29641e3b-f4bd-8cbe-e586-6c87cade9511/AppIcon-prod-1x_U007emarketing-0-7-0-85-220.png/460x0w.webp',
              operatingSystem: 'iOS',
              offers: {
                '@type': 'Offer',
                availability: 'https://schema.org/InStock',
                url: 'https://apps.apple.com/us/app/pga-coach/id1448721089',
              },
              applicationCategory: 'https://schema.org/SportsApplication',
            },
            {
              '@type': 'MobileApplication',
              name: 'PGA Coach',
              description: 'Become the Modern Golf Coach with PGA Coach! Created by the PGA of America and built exclusively for PGA Coaches to engage with golfers through an innovative Coaching Platform. I knew it was for me when the first student scheduled through the booking link and I received an immediate confirmation. — Mark Marshall',
              image: 'https://play-lh.googleusercontent.com/Cx-TLyyEupGQlbTctEjyqFAR8x6La3t-iLeR_hVwxMT6UR1OtntqDBFCI_j5Zw_pDlul=w480-h960-rw',
              operatingSystem: 'Android',
              offers: {
                '@type': 'Offer',
                availability: 'https://schema.org/InStock',
                url: 'https://play.google.com/store/apps/details?id=com.pga.pgacoach',
              },
              applicationCategory: 'https://schema.org/SportsApplication',
            },
          ],
        }}
        />
        <ApolloProvider client={apolloClient}>
          <RefreshThemeProvider>
            <Shell cookies={cookies} embed={embed}>
              <CssBaseline />
              <Component {...pageProps} />
            </Shell>
          </RefreshThemeProvider>
        </ApolloProvider>
        <RaygunActivate />
      </React.Fragment>
    )
  }
}

const RefreshThemeProvider = ({ children }) => {
  return (
    <ThemeProvider theme={themeMUI}>
      {children}
    </ThemeProvider>
  )
}

RefreshThemeProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

// withApolloClient must be initialized after any of the HOCs that create
// a React Context so that Apollo SSR is able to use context values when
// scanning the component tree for GraphQL queries to execute:
//
// <withApolloClient>
//   <withFeatureFlagProvider>
//     <PGAApp>
//   </withFeatureFlagProvider>
// </withApolloClient>
//
// (mind you that `compose` does right-to-left function composition)
export default compose(
  withApolloClient,
  withFeatureFlagProvider,
)(PGAApp)

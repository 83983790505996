/* eslint-disable react/destructuring-assignment */
import PropTypes from 'prop-types'

const Image = (props) => {
  const { className, ...otherProps } = props

  const imageProps = {
    ...otherProps,
    className,
  }
  const image = <img {...imageProps} /> // eslint-disable-line jsx-a11y/alt-text

  return image
}

Image.propTypes = {
  className: PropTypes.string,
}


export default Image

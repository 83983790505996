import React, { useState } from 'react'
import gql from 'graphql-tag'
import { Query } from '@apollo/client/react/components'
import LinearProgress from '@material-ui/core/LinearProgress'
import Error from '@components/error'
import { path } from 'ramda'
import PropTypes from 'prop-types'
import defaults from '@config/defaults'
import LiveBanner from './live-banner'

const QUERY = gql`
query  {
  coachChannelCollection(limit: 1) {
    items {
      liveVideo {
        id
      }
     liveBanner
     liveBannerPollInterval
     liveVideoOverride
  }
}
}
`

export default function BannerWithData(props) {
  const {
    setLiveBanner,
    hasHero,
  } = props

  const [pollInterval, setPollInterval] = useState(
    process.browser ? defaults.liveBannerPollInterval : null,
  )

  return (
    <Query
      query={QUERY}
      pollInterval={pollInterval}
    >
      {({
        loading, error, data,
      }) => {
        if (error) return <Error error={error} />
        if (loading) return <LinearProgress color="secondary" />

        const response = path(['coachChannelCollection', 'items', 0], data)

        if (response && response.liveBannerPollInterval) {
          setPollInterval(response.liveBannerPollInterval * 60 * 1000)
        }

        return response?.liveVideo?.id ? (
          <LiveBanner
            videoId={response.liveVideo.id}
            setLiveBanner={setLiveBanner}
            liveBannerText={response.liveBanner}
            liveVideoOverride={response.liveVideoOverride}
            hasHero={hasHero}
          />
        ) : null
      }}
    </Query>
  )
}

BannerWithData.propTypes = {
  setLiveBanner: PropTypes.func,
  hasHero: PropTypes.bool,
}

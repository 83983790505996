/* eslint-disable jsx-a11y/media-has-caption */
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import { AssetStyle } from './ctfl-asset.styles'
import { Image, Video } from '@components/atoms'

const CtflAsset = (props) => {
  const {
    contentType,
    description,
    url,
    width,
    height,
    poster,
    hideDescription,
    caption,
  } = props

  const maxWidth = width || 1280

  return (
    <AssetStyle style={{ maxWidth: `${maxWidth}px` }}>
      {contentType && contentType.includes('image') && <Image src={`${url}?fl=progressive&q=80`} alt={description} />}
      {contentType && contentType.includes('video') && <Video src={url} controls poster={poster} />}
      {!hideDescription && <Box color="grey.dark" fontSize={12} mt={1} mb={2}>{caption || description}</Box>}
    </AssetStyle>
  )
}

CtflAsset.propTypes = {
  contentType: PropTypes.string,
  description: PropTypes.string,
  caption: PropTypes.string,
  url: PropTypes.string,
  poster: PropTypes.string,
  /** Used to hide the caption, for containing components */
  hideDescription: PropTypes.bool,
  width: PropTypes.number,
}

export default CtflAsset

import { useContext } from 'react'
import FeatureFlagContext from '@lib/feature-flags/FeatureFlagContext'

// React hook for pulling feature flags into a function component
const useFeatureFlags = (flagKeys = []) => {
  const featureFlags = useContext(FeatureFlagContext)

  const flagValues = flagKeys.reduce((agg, key) => {
    return [...agg, featureFlags[key]]
  }, [])

  return flagValues
}

export default useFeatureFlags

import { styled } from '@material-ui/core/styles'

export const RenderersRoot = styled('div')(({ theme }) => ({
  background: theme.palette.secondary.light,
  padding: theme.spacing(1),
}))

export const RichText = styled('div')(({ theme }) => ({
  '& p, & ol, & ul, div.rich-paragraph': {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  '& div.rich-paragraph:first-child': {
    marginTop: 0,
  },
  [theme.breakpoints.up('md')]: {
    fontSize: 16,
  },
  '& li': {
    marginBottom: theme.spacing(4),
  },
  '& li p': {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  '& blockquote': {
    marginLeft: 0,
    padding: `${theme.spacing(1)}px 0 ${theme.spacing(1)}px ${theme.spacing(2)}px`,
    borderLeft: `3px solid ${theme.palette.primary.main}`,
  },
  '& blockquote p:last-child': {
    marginBottom: 0,
  },
  '& > ul': {
    listStyleType: 'none',
    paddingLeft: 20,
  },
  '& > ul li': {
    position: 'relative',
  },
  '& > ul li::before': {
    content: "''",
    position: 'absolute',
    left: -20,
    top: 11,
    height: 5,
    width: 5,
    backgroundColor: theme.palette.primary.main,
    display: 'block',
    borderRadius: 100,
  },
  '& > ol': {
    listStyle: 'none',
    counterReset: 'level1',
    paddingLeft: 30,
  },
  '& > ol li': {
    position: 'relative',
  },
  '& > ol li:before': {
    content: "counter(level1) ''",
    counterIncrement: 'level1',
    position: 'absolute',
    left: -30,
    color: theme.palette.primary.dark,
    fontWeight: 'bold',
    fontSize: 16,
  },
  '& a': {
    textDecoration: 'none',
    boxShadow: `0 2px 0 ${theme.palette.primary.main}`,
    color: theme.palette.grey[600],
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: 'rgba(171,	145,	87, 0.25)',
      color: theme.palette.primary.dark,
    },
  },
}))

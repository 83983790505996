import React from 'react'
import Error from '@components/error'
import FeatureFlagContext from '@lib/feature-flags/FeatureFlagContext'

// HOC for enabling a page via a feature flag
const withFeatureFlagPage = flagKey => (Page) => {
  const WithFeatureFlagPage = (props) => {
    const { ...pageProps } = props
    return (
      <FeatureFlagContext.Consumer>
        {(featureFlags) => {
          const isPageEnabled = (featureFlags && flagKey in featureFlags)
            ? !!featureFlags[flagKey]
            : false
          return isPageEnabled ? <Page {...pageProps} /> : <Error statusCode={404} />
        }}
      </FeatureFlagContext.Consumer>
    )
  }

  WithFeatureFlagPage.displayName = `withFeatureFlagPage(${Page.displayName || Page.name || 'Page'})`

  if (Page.getInitialProps) {
    WithFeatureFlagPage.getInitialProps = Page.getInitialProps
  }

  return WithFeatureFlagPage
}

export default withFeatureFlagPage

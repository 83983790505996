import {
  Box,
  IconButton,
  Container,
  Slide,
  InputBase,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
  Grid,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { useRouter } from 'next/router'
import SearchIcon from '@material-ui/icons/Search'
import Link from '@components/link/index'
import { CtaText } from '@components/atoms/cta-arrow'

import { forwardRef, useRef, useState } from 'react'
import useStyles from './mobile-navigation-drawer.styles'
import MobileUtilityNav from './mobile-utility-nav/index'
import HeaderFeaturedStory from '../common/header-featured-story-list/header-featured-story/header-featured-story'
import { useFeaturedStories } from '../hooks/useFeaturedStories'
import { HeaderFeaturedStoryListQuery } from '../common/header-featured-story-list'


const MobileNavigationDrawer = forwardRef((props, ref) => {
  const {
    show,
    linksCollection,
    utilityNavigationLinksCollection,
  } = props

  const router = useRouter()
  const styles = useStyles()
  const searchInputRef = useRef(null)
  const [searchText, setSearchText] = useState('')
  const executeSearch = () => {
    if (searchText.length >= 3) {
      router.push(`/search?searchText=${searchText}`)
    }
  }

  const [expanded, setExpanded] = useState('')

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.type === 'click') {
      executeSearch()
      window.scrollTo(0, 0)
    }
  }

  const hasUtilityLinks = utilityNavigationLinksCollection
    && utilityNavigationLinksCollection.items
    && utilityNavigationLinksCollection.items.length

  return (
    <Slide direction="right" in={show} mountOnEnter unmountOnExit>
      <Box
        ref={ref}
        className={styles.root}
        data-testid="mobile-sidebar"
        data-cy="navigation-bar"
      >
        <Box bgcolor="common.white">
          <Container>
            <Box display="flex" flexDirection="column" pt="1.25rem">
              <Box display="flex" p={1} className={styles.inputContainer} alignItems="center">
                <InputBase
                  inputRef={searchInputRef}
                  placeholder="Search"
                  value={searchText}
                  onChange={event => setSearchText(event.target.value)}
                  onKeyDown={handleKeyDown}
                  fullWidth
                  className={styles.input}
                />
                <IconButton
                  className={styles.searchButton}
                  onClick={handleKeyDown}
                  color="primary"
                  size="small"
                  aria-label="submit to search"
                >
                  <SearchIcon style={{ width: '13px', height: '13px' }} />
                </IconButton>
              </Box>
              {linksCollection && linksCollection.items.map((link) => {
                const hasSubNavigation = link.subNavigationCollection
                  && !!link.subNavigationCollection?.items?.length

                const subNavigationLinkCount = hasSubNavigation
                  ? link.subNavigationCollection.items[0].linksCollection?.items?.length + 1
                  : 1

                const {
                  hasFeaturedStories,
                  showFeaturedStoriesByCategory,
                  featuredStoryCategoryIds,
                  featuredStoryQueryLimit,
                } = useFeaturedStories(link)

                return (
                  <ExpansionPanel
                    key={link.sys.id}
                    square
                    expanded={expanded === link.sys.id}
                    onChange={handleChange(link.sys.id)}
                    className={styles.accordion}
                  >
                    <ExpansionPanelSummary
                      aria-controls={`${link.sys.id}-content`}
                      id={`${link.sys.id}-header`}
                      className={styles.accordionSummary}
                      aria-label={`${link.text}, Menu with ${subNavigationLinkCount} items, ${expanded === link.sys.id ? 'open' : 'closed'}`}
                    >
                      <Typography
                        variant="h6"
                        variantMapping={{ h6: 'h2' }}
                        className={`${styles.accordionSummaryText} ${expanded === link.sys.id ? 'expanded' : ''}`}
                        data-gtm-nav-item="hamburger"
                      >{link.text}
                      </Typography>
                      <KeyboardArrowDownIcon className={`${styles.chevron} ${expanded === link.sys.id ? 'expanded' : ''}`} />
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails
                      className={styles.accordionDetails}
                    >
                      <Link {...link} anchorProps={{ 'data-gtm-nav-item': 'primary' }}>
                        <CtaText variant="h3" className={styles.headline}>
                          {link.headline || link.text}
                        </CtaText>
                      </Link>
                      {link.description && (
                        <Typography variant="body2" style={{ marginBottom: '1.875rem' }}>
                          {link.description}
                        </Typography>
                      )}
                      {hasSubNavigation && (
                        <ul style={{ padding: 0, margin: 0, listStyle: 'none' }}>
                          {link.subNavigationCollection?.items[0].linksCollection?.items?.map((item) => {
                            const {
                              link: itemLink,
                              externalLink,
                              slug,
                              text,
                              headline,
                              tourName,
                            } = item

                            return (
                              <li key={item.sys.id} className={styles.link}>
                                <Link {...item} anchorProps={{ 'data-gtm-nav-item': 'secondary' }} className={styles.subNavLink}>
                                  <Typography
                                    variant="h6"
                                    variantMapping={{ h6: 'h4' }}
                                  >
                                    {tourName || headline || text}
                                  </Typography>
                                </Link>
                              </li>
                            )
                          })}
                        </ul>
                      )}
                      <Grid className={styles.storiesContainer} container spacing={2} direction="column">
                        {hasFeaturedStories && link.featuredStoriesCollection?.items?.map(item => (
                          <Grid key={item.sys.id} item xs sm={6} style={{ flexGrow: 0.5 }}>
                            <HeaderFeaturedStory item={item} />
                          </Grid>
                        ))}
                        {showFeaturedStoriesByCategory && (
                          <HeaderFeaturedStoryListQuery
                            categoryIds={featuredStoryCategoryIds}
                            containerProps={{ item: true, sm: 6, style: { flexGrow: 0.5 } }}
                            limit={featuredStoryQueryLimit}
                          />
                        )}
                      </Grid>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                )
              })}
            </Box>
          </Container>
        </Box>
        <Container>
          <Box py="1.8125rem">
            {hasUtilityLinks && (
              <MobileUtilityNav utilityNavigationLinksCollection={utilityNavigationLinksCollection} />
            )}
          </Box>
        </Container>
      </Box>
    </Slide>
  )
})

MobileNavigationDrawer.propTypes = {
  show: PropTypes.bool,
  linksCollection: PropTypes.shape({
    items: PropTypes.array,
  }),
  utilityNavigationLinksCollection: PropTypes.shape({
    items: PropTypes.array,
  }),
}

export default MobileNavigationDrawer

import gql from 'graphql-tag'
import { Query } from '@apollo/client/react/components'
import LinearProgress from '@material-ui/core/LinearProgress'
import Error from '@components/error'
import EmbedInlineStory from './embed-inline-story'

export const storyFragment = `
headline
slug
image {
  contentType
  url
  description
  height
  title
  width
}
`

export const QUERY = gql`
query ById($id: String!) {
  story(id: $id) {
    ${storyFragment}
  }
}
`

export default function EmbedInlineStoryWithData(props) {
  return (
    <Query query={QUERY} variables={props}>
      {({
        loading, error, data,
      }) => {
        if (error) return <Error error={error} />
        if (loading) return <LinearProgress color="secondary" />

        const {
          storyCollection,
          story,
        } = data
        const storyData = story
          || (storyCollection && storyCollection.items && storyCollection.items[0])

        return (
          <EmbedInlineStory {...storyData} />
        )
      }}
    </Query>
  )
}

import { styled } from '@material-ui/core/styles'

export const AssetStyle = styled('figure')(() => ({
  margin: '0 auto',
  '& img': {
    width: '100%',
    maxWidth: '100%',
    display: 'block',
    margin: '0 auto',
  },
  '& video': {
    width: '100%',
    maxWidth: '100%',
    display: 'block',
    margin: '0 auto',
  },
  '& video[poster]': {
    backgroundSize: 'cover',
  },
}))

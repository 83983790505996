import Link from '@components/link'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Hidden from '@material-ui/core/Hidden'
import Container from '@material-ui/core/Container'

import PropTypes from 'prop-types'
import useStyles from './global-event-alert.styles'

const GlobalEventAlert = (props) => {
  const {
    item,
  } = props
  const styles = useStyles()

  return (
    <header className={styles.root}>
      <Container>
        <Box display="flex" alignItems="center" color="common.black">
          <Link className={styles.link} link={item.ctaUrl}>{item.ctaText}</Link>
          <Hidden only="xs">
            <Typography variant="body2" className={styles.headline}>{item.headline}</Typography>
          </Hidden>
        </Box>
      </Container>
    </header>
  )
}

GlobalEventAlert.propTypes = {
  item: PropTypes.shape({
    startDateTime: PropTypes.string,
    endDateTime: PropTypes.string,
    ctaText: PropTypes.string,
    ctaUrl: PropTypes.string,
    headline: PropTypes.string,
  }),
}

GlobalEventAlert.defaultProps = {}

export default GlobalEventAlert

import PropTypes from 'prop-types'
import { convertToSlug } from '@utils/slugs'

const types = {
  PageHome: () => ({ href: '/' }),
  Learn: () => ({ href: '/learn' }),
  CoachLanding: () => ({ href: '/coach' }),
  CoursesLanding: () => ({ href: '/play' }),
  Shop: () => ({ href: '/shop' }),
  StoriesPage: () => ({ href: '/stories' }),
  EventsLanding: () => ({ href: '/events' }),
  Event: slug => ({ href: `/events/${slug}` }),
  Story: slug => ({ href: `/story/${slug}` }),
  Leaderboard: slug => ({ href: `/events/leaderboards/${slug}` }),
  Tour: slug => ({ href: `/events/leaderboards/${slug}` }),
  IframeContent: slug => ({ href: `/events/${slug}/embed` }),
  Archive: slug => ({ href: `/archive/${slug}` }),
  Article: slug => ({ href: `/archive/${slug}` }),
  AlgoliaArticle: slug => ({ href: `/archive/${slug}` }),
  Facility: fac => ({
    href: `/play/${fac.state || '-'}/${convertToSlug(fac.city)}/${convertToSlug(fac.name)}/${fac.facility_id}`.toLowerCase(),
  }),
  AlgoliaFacility: fac => ({
    href: `/play/${fac.state || '-'}/${convertToSlug(fac.city)}/${convertToSlug(fac.name)}/${fac.facility_id}`.toLowerCase(),
  }),
  NavLink: slug => ({ href: slug }),
  Page: slug => ({ href: `/pga-of-america/${slug}` }),
  Contact: () => ({ href: '/contact' }),
  About: () => ({ href: '/about' }),
  PromotionalPage: slug => ({ href: `/pga-of-america/${slug}` }),
}

const RenderLinkProps = ({ type, slug }) => (
  types[type] ? types[type](slug) : { href: '/404' }
)

RenderLinkProps.propTypes = {
  type: PropTypes.string,
  slug: PropTypes.string,
}

export default RenderLinkProps

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: () => ({
    display: 'block',
    borderRadius: '8px',
    boxShadow: '0px 4px 36px rgba(0, 10, 22, 0.13)',
  }),
  container: {
  },
  image: {
    width: '100%',
    height: 'auto',
    maxHeight: '150px',
    objectFit: 'cover',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
  },
  content: {
    padding: '1.25rem 2rem 1.65rem',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
    height: '125px',
  },
  headline: {
    color: theme.palette.secondary.light,
    width: '100%',
    display: '-webkit-box',
    boxOrient: 'vertical',
    overflow: 'hidden',
    lineClamp: '2',
    height: '2.5em',
    lineHeight: '1.25em',
    textOverflow: 'ellipsis',
    marginBottom: '.25rem',
  },
  cta: {
    color: theme.palette.blue.main,
    fontWeight: 700,
    lineHeight: 1.75,
  },
}))

export default useStyles

import { styled } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

export const PullquoteRoot = styled('div')(({ theme }) => ({
  position: 'relative',
  margin: `${theme.spacing(4)}px 0`,
}))

export const Quote = styled(Typography)(() => ({
  lineHeight: 1.5,
  '& div.rich-paragraph': {
    fontSize: 'inherit',
    marginBottom: '.5em',
  },
}))

export const QuoteMark = styled(Box)(() => ({
  position: 'relative',
  height: 55,
  top: -40,
  left: -4,
}))

export const Attribution = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  fontSize: 14,
  lineHeight: 1.7,
  letterSpacing: 0.25,
  '&::before': {
    content: "' '",
    position: 'relative',
    top: -4,
    display: 'inline-block',
    height: 1,
    width: 14,
    background: theme.palette.text.primary,
  },
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(1),
  },
}))

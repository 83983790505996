import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { Query } from '@apollo/client/react/components'
import LinearProgress from '@material-ui/core/LinearProgress'
import Error from '@components/error'
import storyCardFragment from '@schema/story-card'
import Header from './header'

const storyFragment = `
${storyCardFragment}
`

const QUERY = gql`
query NavMainRefresh {
  navigationMainRefreshCollection(limit: 1) {
    items {
      image {
        url
      }
      linksCollection(limit: 8) {
        total
        items {
          sys {
            id
          }
          text
          link
          externalLink
          headline
          description
          subNavigationCollection(limit: 6) {
            items {
              sys {
                id
              }
              headline
              linksCollection {
                total
                items {
                  __typename
                  ... on Event {
                    sys {
                      id
                    }
                    headline
                    slug
                  }
                  ... on Story {
                    sys {
                      id
                    }
                    headline
                    slug
                  }
                  ... on NavLink {
                    sys {
                      id
                    }
                    text
                    link
                    externalLink
                  }
                  ... on Tour {
                    sys {
                      id
                    }
                    headline
                    tourName
                    slug
                  }
                  ... on Page {
                    sys {
                      id
                    }
                    headline
                    slug
                  }
                  ... on IframeContent {
                    sys {
                      id
                    }
                    headline
                    slug
                  }
                }
              }
            }
          }
          featuredStoriesCollection(limit: 2) {
            total
            items {
              __typename
              ... on Event {
                sys {
                  id
                }
                headline
                slug
                image {
                  url
                }
              }
              ... on Story {
                sys {
                  id
                }
                ${storyFragment}
              }
            }
          }
          featuredStoryCategoriesCollection(limit: 3) {
            total
            items {
              sys {
                id
              }
            }
          }
        }
      }
      utilityNavigationLinksCollection(limit: 8) {
        items {
          sys {
            id
          }
          text
          link
          externalLink
          thumbnailImage {
            url
          }
        }
      }
    }
  }
}

`

export default function HeaderWithData(props) {
  const {
    isHome,
    pathname,
    hasLiveBanner,
  } = props
  return (
    <Query query={QUERY}>
      {({
        loading, error, data,
      }) => {
        if (error) return <Error error={error} />
        if (loading) return <LinearProgress color="secondary" />
        const response = data && data.navigationMainRefreshCollection
          && data.navigationMainRefreshCollection.items
          && data.navigationMainRefreshCollection.items[0]

        return (
          <Header
            isHome={isHome}
            pathname={pathname}
            image={response && response.image}
            linksCollection={response && response.linksCollection}
            utilityNavigationLinksCollection={response && response.utilityNavigationLinksCollection}
            hasLiveBanner={hasLiveBanner}
          />
        )
      }}
    </Query>
  )
}

HeaderWithData.propTypes = {
  isHome: PropTypes.bool,
  pathname: PropTypes.string,
  hasLiveBanner: PropTypes.bool,
}

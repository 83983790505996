import { makeStyles } from '@material-ui/core/styles'

const bottomOffset = '0px'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    height: `calc(100vh - 100% - ${bottomOffset})`,
    left: 0,
    right: 0,
    bottom: `calc(-1 * 100vh + 100% + ${bottomOffset})`,
    backgroundColor: theme.palette.offwhite.main,
    zIndex: 1,
    overflow: 'auto',
  },
  searchButton: {
    height: '24px',
    width: '24px',
  },
  input: {
    fontSize: '.875rem',
  },
  headline: {
    color: theme.palette.secondary.light,
    marginBottom: '.8125rem',
    ...theme.typography.h6,
  },
  accordion: {
    background: 'transparent',
    boxShadow: 'none',
    '&:before': {
      backgroundColor: theme.palette.grey.light,
      transition: 'none',
      opacity: 1,
    },
    '&.Mui-expanded': {
      margin: '0',
      '&:before': {
        opacity: 1,
      },
    },
  },
  accordionSummary: {
    padding: 0,
    '&.Mui-expanded': {
      minHeight: 'initial',
    },
    '& .MuiExpansionPanelSummary-content': {
      margin: '1.125rem 0',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  accordionSummaryText: {
    transitionProperty: 'color',
    transitionDuration: theme.transitions.duration.shortest,
    transitionTimingFunction: theme.transitions.easing.easeInOut,
    fontSize: '1.125rem',
    '&.expanded': {
      color: theme.palette.primary.dark,
    },
  },
  accordionDetails: {
    paddingLeft: 0,
    paddingRight: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  link: {
    fontSize: '.875rem',
    marginBottom: '1.5625rem',
  },
  chevron: {
    transitionProperty: 'transform',
    transitionDuration: theme.transitions.duration.shortest,
    transitionTimingFunction: theme.transitions.easing.easeInOut,
    '&.expanded': {
      transform: 'rotate(-180deg)',
    },
  },
  storiesContainer: {
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  subNavLink: {
    display: 'block',
  },
}))

export default useStyles

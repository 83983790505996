import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(4),
    '& a': {
      display: 'block',
      padding: '2px 0',
    },
  },
}))

export default useStyles

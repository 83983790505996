const styles = {
  background: 'rgba(0, 0, 0, 0.4)',
  color: '#fff',
  padding: '1px 4px 1px 22px',
  margin: '1px',
  fontFamily: 'monospace',
  borderTop: '1px solid #444',
}

const keyStyle = {
  display: 'inline-block',
  paddingRight: '10px',
}

const Key = ({ ky }) => <div style={keyStyle}>{ky} </div>
const Num = ({ num }) => <span style={{ fontWeight: 'bold' }}>{num}</span>

const types = {
  string: (key, val) => <div key={key}><Key ky={key} />: &quot;{val}&quot;</div>,
  number: (key, val) => <div key={key}><Key ky={key} />: <Num num={val} /></div>,
  object: (key, val) => <div>{key}: &#123; <PrintProps {...val} /></div>,
}

const PrintProps = props => (
  <div style={styles}>
    {Object.keys(props).map((key) => {
      const type = typeof (props[key])

      if (props[key] == null) {
        return <div style={{ background: 'rgba(255, 0, 0, .1)' }}>{key}: null</div>
      }

      return types[type] ? types[type](key, props[key]) : <div>====== FIXME ======</div>
    })}
  </div>
)

export default PrintProps

import PropTypes from 'prop-types'
import QuoteIcon from './quote-icon'
import {
 PullquoteRoot, Quote, QuoteMark, Attribution,
} from './pullquote.styles'


const Pullquote = (props) => {
  const { attribution, text, hideQuote } = props
  return (
    <PullquoteRoot>
      {!hideQuote && <QuoteIcon viewBox='0 0 36 36' style={{ width: '36px', height: '36px' }} />}
      <Quote variant="h4">
        {text}
      </Quote>
      {attribution && <Attribution> {attribution}</Attribution>}
    </PullquoteRoot>
  )
}

Pullquote.propTypes = {
  attribution: PropTypes.string,
  text: PropTypes.string,
  hideQuote: PropTypes.bool,
}

Pullquote.defaultProps = {
  hideQuote: false,
}

export default Pullquote

/* eslint-disable camelcase */
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Link from '@components/link'
import { Image } from '@components/atoms'
import {
  useStyles,
  EmbedInlineStoriesRoot,
  ArticleHeroRoot,
  AssetStyle,
} from './articles-block-embed.styles'

const ArticlesBlockEmbed = (props) => {
  const {
    articlesCollection: { items },
  } = props

  const s = useStyles()
  const width = 100
  const headline = 'More from PGA'


  const logo = '/images/logo-blue.png'

  return (
    <EmbedInlineStoriesRoot>
      <Typography variant="overline">{headline}</Typography>
      {items.map(story => (
        <Link __typename="Story" slug={story.slug} key={story.slug}>
          {(story.image && (
            <ArticleHeroRoot>
              <Box pr={{ xs: 1, sm: 1, md: 1 }} pt={{ xs: 1, sm: 1, md: 1 }} pb={{ xs: 1, sm: 1, md: 1 }}>
                <AssetStyle style={{ width: `${width}px` }}>
                  <Image src={`${story.image.url}?fl=progressive&q=80&w=120&h=80`} alt={story.image.description || 'image'} />
                </AssetStyle>
              </Box>
              <Typography variant="subtitle2" className={s.embedHeadline}>{story.headline}</Typography>
            </ArticleHeroRoot>
          ))}
          {(!story.image && (
            <ArticleHeroRoot>
              <Box pr={{ xs: 1, sm: 1, md: 1 }} pt={{ xs: 1, sm: 1, md: 1 }} pb={{ xs: 1, sm: 1, md: 1 }}>
                <AssetStyle style={{ width: `${width}px` }}>
                  <Image src={logo} alt="PGA logo" width="100%" />
                </AssetStyle>
              </Box>
              <Typography variant="subtitle2" className={s.embedHeadline}>{story.headline}</Typography>
            </ArticleHeroRoot>
          ))}
        </Link>
      ))}
    </EmbedInlineStoriesRoot>
  )
}

ArticlesBlockEmbed.propTypes = {
  articlesCollection: PropTypes.shape({
    items: PropTypes.array,
  }),
}

ArticlesBlockEmbed.defaultProps = {
  articlesCollection: {
    items: [],
  },
}

export default ArticlesBlockEmbed

import { IconClose } from '@components/atoms/icons'
import {
  Box,
  Fade,
  IconButton,
  Container,
} from '@material-ui/core'
import PropTypes from 'prop-types'

import { Transition } from 'react-transition-group'
import DesktopNavigationDrawerMenu from './desktop-navigation-drawer-menu'
import useStyles from './desktop-navigation-drawer.styles'


export default function DesktopNavigationDrawer(props) {
  const {
    showSlide, toggleDrawer, selectedLink,
    drawerEntered, onEntered,
  } = props
  const styles = useStyles()

  const handleOnEntered = () => {
    if (drawerEntered === false) { onEntered() }
  }

  const transitionStyles = drawerEntered === false ? {
    entering: {
      transform: 'translateX(0)',
      visibility: 'visible',
    },
    entered: {
      transform: 'translateX(0)',
      visibility: 'visible',
    },
    exiting: {
      transform: 'translateX(-100%)',
      visibility: 'visible',
    },
    exited: {
      transform: 'translateX(-100%)',
      visibility: 'hidden',
    },
  } : {
    entering: { opacity: 1, visibility: 'visible' },
    entered: { opacity: 1, visibility: 'visible' },
    exiting: { opacity: 0, visibility: 'visible' },
    exited: { opacity: 0, visibility: 'hidden' },
  }

  const defaultStyle = drawerEntered === false ? {
    transition: 'transform 500ms cubic-bezier(0, 0, 0.2, 1) 0ms',
    transform: 'translateX(-100%)',
    visibility: 'hidden',
  } : {
    opacity: 0,
    visibility: 'hidden',
  }

  return (
    <Transition in={showSlide} timeout={500} onEntered={handleOnEntered}>
      {state => (
        <Box
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
          className={styles.root}
          p="2.5rem"
          data-testid={`desktop-sidebar-${selectedLink.text}`}
        >
          <Container>
            <Box display="flex" justifyContent="flex-end">
              <IconButton aria-label="Close Menu" onClick={() => toggleDrawer()}>
                <IconClose />
              </IconButton>
            </Box>
            <Fade timeout={500} in={showSlide}>
              <Box>
                {selectedLink && (
                  <DesktopNavigationDrawerMenu link={selectedLink} />
                )}
              </Box>
            </Fade>
          </Container>
        </Box>
      )
      }
    </Transition>
  )
}

DesktopNavigationDrawer.propTypes = {
  selectedLink: PropTypes.object,
  showSlide: PropTypes.bool,
  toggleDrawer: PropTypes.func,
  drawerEntered: PropTypes.bool,
  onEntered: PropTypes.func,
}

import gql from 'graphql-tag'
import { Query } from '@apollo/client/react/components'
import LinearProgress from '@material-ui/core/LinearProgress'
import NoSsr from '@material-ui/core/NoSsr'
import Error from '@components/error'
import richTextFragment from '@components/renderers/rich-text.fragment'
import Banner from './banner'

const ONE_TRUST_QUERY = gql`
  query GdprBanner {
    globalCollection(limit: 1, where: {title: "OneTrust GDPR Content"}) {
      items {
        gdprText {
          ${richTextFragment}
        }
      }
    }
  }
`

export default function GDPRBannerQuery(props) {
  return (
    <Query query={ONE_TRUST_QUERY}>
      {({
        loading, error, data,
      }) => {
        if (error) return <Error error={error} />
        if (loading) return <LinearProgress color="secondary" />
        const response = data.globalCollection
        let richText = {}
        if (response.items && response.items.length > 0) {
          // eslint-disable-next-line prefer-destructuring
          richText = response.items[0]
        }
        return (
          <NoSsr>
            <Banner {...props} {...richText} />
          </NoSsr>
        )
      }}
    </Query>
  )
}

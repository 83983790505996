import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.grey[100],
    color: theme.palette.secondary.main,
    padding: theme.spacing(4),
  },
}))

export default useStyles

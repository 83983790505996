import React from 'react'
import PropTypes from 'prop-types'
import FeatureFlagContext from '@lib/feature-flags/FeatureFlagContext'
import initializeFeatures from '@lib/feature-flags/initializeFeatures'

function withFeatureFlagProvider(App) {
  class WithFeatureFlagProvider extends React.Component {
    static displayName = `withFeatureFlagProvider(${App.displayName || App.name || 'App'})`

    static propTypes = {
      features: PropTypes.object,
    }

    static async getInitialProps(props) {
      const { ctx } = props
      const features = await initializeFeatures(ctx)

      let appInitialProps = {}
      if (App.getInitialProps) {
        appInitialProps = await App.getInitialProps(props)
      }

      return {
        features,
        ...appInitialProps,
      }
    }

    render() {
      const { features, ...appInitialProps } = this.props
      return (
        <FeatureFlagContext.Provider value={features}>
          <App {...appInitialProps} />
        </FeatureFlagContext.Provider>
      )
    }
  }
  return WithFeatureFlagProvider
}

export default withFeatureFlagProvider

/* eslint-disable camelcase */
import PropTypes from 'prop-types'
import RenderLinkProps from '@components/renderers/render-link'
import NextLink from 'next/link'
import SrOnly from '@components/atoms/sr-only'
import useTransformLink from '@hooks/useTransformLink'
import useStyles from './link.styles'
import createTags from './gtm'

const sanitizeUrl = url => url.replace('https://www.pga.com/', '')

const Link = (props) => {
  const {
    __typename,
    slug,
    url,
    children,
    link,
    externalLink,
    noBorder,
    className,
    variant,
    object_type,
    anchorProps,
  } = props
  const transformedLink = useTransformLink(link)
  const externalUrls = transformedLink || externalLink

  let type = __typename
  if (__typename === 'SearchResultItem' || __typename === 'AlgoliaSearchResultItem') {
    if (object_type === 'contentful_story') type = 'Story'
    if (object_type === 'contentful_event') type = 'Event'
    if (object_type === 'legacy_article') type = 'Archive'
    if (object_type === 'legacy_videos') type = 'Archive'
    if (object_type === 'legacy_event_article') type = 'Archive'
  }

  let override = null
  if (type === 'Facility' || type === 'AlgoliaFacility') override = props

  const s = useStyles()
  const href = RenderLinkProps({ slug: slug || override || (url && sanitizeUrl(url)), type })
  const external = externalUrls && { href: externalUrls }
  const linkProps = external || href
  const target = externalLink ? '_blank' : null

  const tags = createTags(props, type)

  return (externalLink || transformedLink) ? (
    <a
      href={externalLink || transformedLink}
      {...tags}
      className={`${variant ? s[variant] : ''} ${noBorder ? s.noBorder : ''} ${className || ''}`}
      target={target}
      {...anchorProps}
    >
      {children} {externalLink && (<SrOnly>, external transformedLink</SrOnly>)}
    </a>
  ) : (
    <NextLink {...linkProps} prefetch={false}>
      <a
        {...tags}
        className={`${variant ? s[variant] : ''} ${noBorder ? s.noBorder : ''} ${className || ''}`}
        {...anchorProps}
      >
        {children}
      </a>
    </NextLink>
  )
}

Link.propTypes = {
  variant: PropTypes.oneOf(['default', 'card', 'fancyLink']),
  noBorder: PropTypes.bool,
  __typename: PropTypes.string,
  slug: PropTypes.string,
  url: PropTypes.string,
  children: PropTypes.any,
  link: PropTypes.string,
  externalLink: PropTypes.string,
  className: PropTypes.string,
  object_type: PropTypes.string,
  anchorProps: PropTypes.object,
}

Link.defaultProps = {
  anchorProps: {},
}

export default Link

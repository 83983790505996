import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useRouter } from 'next/router'
import Box from '@material-ui/core/Box'
import { NextSeo } from 'next-seo'
import { HeaderQuery } from '@components/header'
import { FooterQuery } from '@components/footer'
import { GDPRBannerQuery } from '@components/banner'
import { LiveBannerQuery } from '@components/live-banner'
import { globalStyles } from './shell.styles'

const Shell = (props) => {
  const { cookies, embed, children } = props
  const router = useRouter()
  globalStyles()

  // don't render the header/footer if this is an embedded page
  if (embed === 'true') {
    return (
      <>
        {children}
      </>
    )
  }

  // don't render the header/footer for the /auth pages
  if (router?.pathname.startsWith('/auth')) {
    return (
      <>
        {children}
      </>
    )
  }

  // TODO: have individual pages report up when they have a hero
  const pathsWithHero = ['/', '/events', '/play', '/stories', '/my-game', '/learn']
  const pathsWithoutFooter = ['/things-to-do/search']
  const hasHero = pathsWithHero.includes(router ? router.pathname : null)
  const hasMap = pathsWithoutFooter.includes(router ? router.pathname : null)
  const disableLiveBannerPaths = ['/iconic-insights', '/coach/[slug]']
  const showLiveBanner = !disableLiveBannerPaths.includes(router ? router.pathname : null)

  const hasOneTrustGDPRConsent = cookies && cookies.OptanonAlertBoxClosed

  const liveBannerDismissed = cookies && cookies.pga_dismissLiveBanner && cookies.pga_dismissLiveBanner === 'true'

  const [liveBanner, setLiveBanner] = useState(false)
  const HeaderComponent = (
    <HeaderQuery
      isHome={hasHero}
      pathname={router && router.asPath}
      hasLiveBanner={liveBanner}
    />
  )

  const FooterComponent = <FooterQuery gdprBannerVisible={!hasOneTrustGDPRConsent} />

  const shellPadding = 0

  return (
    <Box pt={shellPadding} style={hasHero ? { paddingTop: 0 } : {}}>
      <NextSeo
        title="PGA of America"
      />
      {HeaderComponent}
      <Box id="main" component="main" minHeight="calc(100vh - 400px)">
        {showLiveBanner && !liveBannerDismissed
        && (
          <LiveBannerQuery
            setLiveBanner={setLiveBanner}
            hasHero={hasHero}
          />
        )}
        {children}
      </Box>
      {!hasOneTrustGDPRConsent && <GDPRBannerQuery />}
      {!hasMap && FooterComponent}
    </Box>
  )
}

Shell.propTypes = {
  cookies: PropTypes.object,
  children: PropTypes.node,
  embed: PropTypes.string,
}

export default Shell

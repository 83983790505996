import { styled, makeStyles } from '@material-ui/core/styles'

export const EmbedInlineStoryRoot = styled('div')(({ theme }) => ({
  padding: theme.spacing(0),
  backgroundColor: 'white',
  overflow: 'hidden',
  maxWidth: '40%',
  float: 'left',
  marginBottom: '1%',
  marginTop: '1%',
  marginRight: '2%',
  border: `1px solid ${theme.palette.grey.light}`,
}))

export const useStyles = makeStyles(() => ({
  embedHeadline: {
    color: 'black',
    textAlign: 'left',
    fontSize: '1.1em',
    padding: '0.25em',
  },
}))

import { makeStyles } from '@material-ui/core/styles'

export const globalStyles = makeStyles(theme => ({
  '@global': {
    '*:focus': {
      outline: 'solid',
    },
    a: {
      color: theme.palette.common.black,
      textDecoration: 'none',
    },
    'a:hover': {
      color: theme.palette.grey.dark,
    },
    p: {
      margin: `0 0 ${theme.spacing(4)}px`,
    },
    [theme.breakpoints.up('md')]: {
      p: {
        fontSize: '16px',
      },
    },
  },
}))

import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { Query } from '@apollo/client/react/components'
import LinearProgress from '@material-ui/core/LinearProgress'
import NoSsr from '@material-ui/core/NoSsr'
import Error from '@components/error'
import storyCardFragment from '@schema/story-card'
import HeaderFeaturedStoryList from './header-featured-story-list'

const storyFragment = `
${storyCardFragment}
`
const QUERY = gql`
query StoriesBySeriesId($where: StoryFilter, $limit: Int) {
  storyCollection(
    limit: $limit
    where: $where
    order: [sys_firstPublishedAt_DESC]
    ) {
    items {
      sys {
        id
      }
      ${storyFragment}
    }
  }
}
`

export default function HeaderFeaturedStoryListWithData(props) {
  const {
    categoryIds,
    containerProps,
    limit,
  } = props

  const orQuery = categoryIds.reduce((prev, curr) => {
    prev.push({ cat: { sys: { id: curr } } })
    return prev
  }, [])

  return (
    <NoSsr>
      <Query query={QUERY} variables={{ where: { OR: orQuery }, limit }}>
        {({
          loading, error, data,
        }) => {
          if (error) return <Error error={error} />
          if (loading) return <LinearProgress color="secondary" />

          const response = data && data.storyCollection
            && data.storyCollection.items.map(item => ({ ...item, __typename: 'Story' }))
          return (
            <HeaderFeaturedStoryList items={response} containerProps={containerProps} />
          )
        }}
      </Query>
    </NoSsr>
  )
}

HeaderFeaturedStoryListWithData.propTypes = {
  categoryIds: PropTypes.array,
  containerProps: PropTypes.object,
  limit: PropTypes.number,
}

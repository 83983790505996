import gql from 'graphql-tag'

export const assetQuery = gql`
query Asset($id: String!) {
  asset(id: $id) {
    contentType
    url
    description
    height
    title
    width
  }
}
`

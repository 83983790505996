/* eslint-disable react/prop-types */
import PropTypes from 'prop-types'
import { CtflTableQuery } from '@components/ctfl-table'
import { PullquoteQuery } from '@components/pullquote'
import { SocialQuery } from '@components/social-embed'
import { ArticlesBlockEmbedQuery } from '@components/articles-block-embed'
import { ContentPromoBlockQuery } from '@components/content-promo-block'
import Error from '@components/error'

const types = {
  Table: ({ sys }) => <CtflTableQuery {...sys} />,
  PullQuote: ({ sys }) => <PullquoteQuery {...sys} />,
  EmbedSocial: ({ sys }) => <SocialQuery {...sys} />,
  RecommendedArticles: ({ sys }) => <ArticlesBlockEmbedQuery {...sys} />,
  ContentPromoBlock: ({ sys }) => <ContentPromoBlockQuery {...sys} embed />,
}

const RenderEmbeddedEntry = (props) => {
  const { type, sys } = props
  return (
    types[type] ? types[type](props) : <Error {...sys} type={type} />
  )
}

RenderEmbeddedEntry.propTypes = {
  type: PropTypes.string,
  sys: PropTypes.shape({}),
}

export default RenderEmbeddedEntry

import { styled, makeStyles } from '@material-ui/core/styles'

export const EmbedInlineStoriesRoot = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  overflow: 'hidden',
  maxWidth: '50%',
  float: 'left',
  marginLeft: '1%',
  marginBottom: '1%',
  marginTop: '1%',
  marginRight: '2%',
  border: `1px solid ${theme.palette.grey.light}`,
  borderRadius: theme.spacing(0.3),
  [theme.breakpoints.down('xs')]: {
    float: 'none',
    maxWidth: '100%',
  },
}))

export const ArticleHeroRoot = styled('div')(({ theme }) => ({
  background: theme.palette.background.paper,
  display: 'flex',
  borderBottom: `1px solid ${theme.palette.divider}`,
  marginBottom: theme.spacing(2),
}))

export const AssetStyle = styled('figure')(() => ({
  margin: '0 auto',
  '& img': {
    width: '100%',
    maxWidth: '100%',
    display: 'block',
    margin: '0 auto',
  },
}))

export const useStyles = makeStyles(theme => ({
  embedHeadline: {
    color: 'black',
    textAlign: 'left',
    fontSize: '1em',
    padding: '0.25em',
    lineHeight: theme.spacing(0.2),
  },
}))

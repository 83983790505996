import Image from '@components/atoms/image'
import Link from '@components/link/index'
import PropTypes from 'prop-types'
import useStyles from './utility-nav-link.styles'

const UtilityNavLink = (props) => {
  const {
    link,
    className,
  } = props
  const styles = useStyles()

  return (
    <Link key={link.sys.id} className={`${styles.root} ${className}`} {...link}>
      <Image
        className={styles.thumbnail}
        width={13}
        height={13}
        src={link.thumbnailImage.url}
        alt={`${link.text} icon`}
      />
      {link.text}
    </Link>
  )
}

UtilityNavLink.propTypes = {
  link: PropTypes.shape({
    sys: PropTypes.shape({
      id: PropTypes.string,
    }),
    text: PropTypes.string,
    link: PropTypes.string,
    externalLink: PropTypes.string,
    thumbnailImage: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
  className: PropTypes.string,
}

UtilityNavLink.defaultProps = {
  className: '',
}

export default UtilityNavLink

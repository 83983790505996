import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import Router from 'next/router'
import IconButton from '@material-ui/core/IconButton'
import InputBase from '@material-ui/core/InputBase'
import Box from '@material-ui/core/Box'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import SearchIcon from '@material-ui/icons/Search'

const HeaderSearchButton = (props) => {
  const { className } = props
  const searchInputRef = useRef(null)
  const [searchText, setSearchText] = useState('')
  const searchAnchorRef = useRef(null)
  const [searchPopupOpen, setSearchPopupOpen] = useState(false)

  useEffect(() => {
    if (searchPopupOpen) {
      searchInputRef.current.focus()
    }
  }, [searchPopupOpen])

  const executeSearch = () => {
    if (searchText.length >= 3) {
      Router.push(`/search?searchText=${searchText}`)
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.type === 'click') {
      executeSearch()
      window.scrollTo(0, 0)
      setSearchPopupOpen(false)
    }
  }

  return (
    <>
      <button
        type="button"
        className={className}
        ref={searchAnchorRef}
        aria-label="search"
        aria-haspopup="true"
        onClick={() => setSearchPopupOpen(!searchPopupOpen)}
        data-gtm-header-search
      >
        <SearchIcon />
        Search
      </button>
      <Popper open={searchPopupOpen} anchorEl={searchAnchorRef.current} role={undefined} placement="bottom-end" transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={() => setSearchPopupOpen(false)}>
                <Box display="flex" p={1}>
                  <InputBase
                    inputRef={searchInputRef}
                    placeholder="Search"
                    value={searchText}
                    onChange={event => setSearchText(event.target.value)}
                    onKeyDown={handleKeyDown}
                    fullWidth
                  />
                  <IconButton onClick={handleKeyDown} aria-label="submit to search">
                    <SearchIcon />
                  </IconButton>
                </Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

HeaderSearchButton.propTypes = {
  className: PropTypes.string,
}

export default HeaderSearchButton

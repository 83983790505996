import { forwardRef } from 'react'

const Video = forwardRef((props, ref) => {
  const { ...otherProps } = props


  const videoProps = {
    ...otherProps,
  }
  const video = <video ref={ref} {...videoProps} /> // eslint-disable-line jsx-a11y/media-has-caption

  return video
})

export default Video

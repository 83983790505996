import gql from 'graphql-tag'
import { Query } from '@apollo/client/react/components'
import LinearProgress from '@material-ui/core/LinearProgress'
import Error from '@components/error'
import SocialEmbed from './social-embed'

export const fragment = `
  title
  socialMediaType
  socialContent
`

const QUERY = gql`
query EmbedSocial($id: String!) {
  embedSocial(id: $id) {
    ${fragment}
  }
}
`

export default function SocialEmbedWithData(props) {
  return (
    <Query query={QUERY} variables={props}>
      {({
        loading, error, data,
      }) => {
        if (error) return <Error error={error} />
        if (loading) return <LinearProgress color="secondary" />
        const response = data.embedSocial
        return (
          <SocialEmbed {...response} />
        )
      }}
    </Query>
  )
}

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.common.white,
    boxShadow: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  shadow: {
    height: '100%',
    backgroundColor: 'transparent',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    boxShadow: '0px 4px 36px rgba(0, 10, 22, 0.13)',
    zIndex: 5,
  },
  logo: {
    zIndex: 10,
    display: 'flex',
    alignItems: 'center',
    outline: 'none',
    '&:visited': {
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  nav: {
    height: '60px',
  },
  navLink: ({ isTransparent }) => ({
    display: 'inline-block',
    padding: `${theme.spacing(1)}px 0`,
    margin: `${theme.spacing(1)}px 0`,
    background: 'transparent',
    outline: 'none',
    borderBottom: '3px solid transparent',
    boxSizing: 'border-box',
    opacity: 0.75,
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: 1.75,
    letterSpacing: 1.25,
    textTransform: 'uppercase',
    color: isTransparent ? 'white' : theme.palette.text.primary,
    transition: 'opacity .3s ease, color .2s linear',
    '&:hover': {
      opacity: 1,
      color: isTransparent ? 'white' : 'black',
    },
    '&.is-hovered': {
      opacity: 1,
      borderBottom: `3px solid ${isTransparent ? 'white' : theme.palette.secondary.light}`,
    },
    '&.is-selected': {
      opacity: 1,
      lineHeight: '10px',
      borderBottom: `2px solid ${theme.palette.primary.main}`,
    },
    '& svg': {
      position: 'relative',
      top: 2,
      [theme.breakpoints.only('xs')]: {
        top: 4,
      },
    },
  }),
  iconNavLink: ({ isTransparent }) => ({
    opacity: 0.75,
    '&:hover': {
      opacity: 1,
    },
    '& .MuiSvgIcon-root': {
      color: isTransparent ? 'white' : theme.palette.text.primary,
    },
  }),
  menuList: ({ isTransparent }) => ({
    display: 'flex',
    '& li': {
      alignItems: 'flex-start',
      '&:not(:last-child)': {
        borderRight: `1px solid ${isTransparent ? theme.palette.divider : theme.palette.grey.light}`,
      },
      '& button': {
        paddingLeft: 0,
      },
      '&:hover': {
        cursor: 'auto',
      },
    },
  }),
  withSubNav: ({ isTransparent }) => ({
    '& button.chosen-link': {
      borderBottom: `3px solid ${isTransparent ? 'white' : theme.palette.secondary.light}`,
    },
  }),
  submenuItem: ({ isTransparent }) => ({
    color: isTransparent ? theme.palette.secondary.light : 'white',
    fontSize: '1rem',
    paddingBottom: theme.spacing(1),
  }),
  search: ({ isTransparent }) => ({
    display: 'flex',
    marginLeft: theme.spacing(4),
    color: isTransparent ? 'white' : 'initial',
    borderBottom: isTransparent ? '1px solid rgba(255, 255, 255, 0.5)' : '1px solid rgba(0, 0, 0, 0.5)',
    fontFamily: theme.typography.h1.fontFamily,
    fontSize: 24,
    '& button': {
      padding: 0,
    },
    '& .MuiInputBase-root': {
      color: isTransparent ? 'white' : theme.palette.secondary.main,
    },
  }),
  navGroup: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    '& h5': {
      paddingBottom: theme.spacing(2),
    },
  },
  subnavHeader: ({ isTransparent }) => ({
    color: isTransparent ? theme.palette.primary.main : 'white',
  }),
  subnavWrap: ({ isTransparent }) => ({
    opacity: '0.90',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    backgroundColor: isTransparent ? 'rgba(255, 255, 255, 0.95)' : `${theme.palette.secondary.light}F5`,
  }),
}))

export default useStyles

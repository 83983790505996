import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import Link from 'next/link'
import Router from 'next/router'
import AppBar from '@material-ui/core/AppBar'
import Container from '@material-ui/core/Container'
import Toolbar from '@material-ui/core/Toolbar'
import NoSsr from '@material-ui/core/NoSsr'
import PGALogo from '@components/atoms/icons/pga-logo'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { HamburgerMenuIcon, IconClose } from '@components/atoms/icons'

import useClickOutside from '@hooks/useClickOutside'
import useStyles from './header.styles'
import MobileNavigationDrawer from './mobile-navigation-drawer'
import { GlobalEventAlertQuery } from '../common/global-event-alert/index'

const HamburgerMenuHeader = (props) => {
  const [showNav, toggleNav] = useState(false)
  const styles = useStyles()
  const {
    linksCollection,
    utilityNavigationLinksCollection,
  } = props

  function handleCloseNav() {
    toggleNav(false)
  }

  useEffect(() => {
    Router.events.on('routeChangeStart', handleCloseNav)
    return () => {
      Router.events.off('routeChangeStart', handleCloseNav)
    }
  }, [])

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (showNav) {
      // Prevent scrolling while drawer is open.
      if (process.browser) {
        const originalStyle = window.getComputedStyle(document.body).overflow
        document.body.style.overflow = 'hidden'
        return () => {
          document.body.style.overflow = originalStyle
        }
      }
    }
  }, [showNav])

  const ref = useRef(null)

  const clickedOutside = useClickOutside(ref)

  useEffect(() => {
    if (showNav) { handleCloseNav() }
  }, [clickedOutside])

  return (
    <AppBar position="sticky" className={styles.whiteNav}>
      <NoSsr>
        <GlobalEventAlertQuery />
      </NoSsr>
      <Box className={styles.containerWrapper}>
        <Container>
          <Toolbar
            disableGutters
            style={{
              justifyContent: 'space-between',
            }}
            className={styles.container}
          >
            <Link href="/" passHref>
              <a title="PGA Home Page" style={{ display: 'flex' }} data-gtm-nav-item="main">
                <PGALogo />
              </a>
            </Link>
            <Box display="flex">
              <button
                type="button"
                onClick={() => toggleNav(!showNav)}
                className={styles.hamburger}
                data-gtm-hamburger
                data-cy={showNav ? 'close-nav' : 'hamburger-menu'}
                data-gtm-nav-item="main"
              >
                {!showNav && (
                  <>
                    <Typography
                      variant="overline"
                      aria-label="open main menu"
                      style={{ fontWeight: 500 }}
                    >Menu
                    </Typography>
                    <HamburgerMenuIcon
                      style={{ marginLeft: '.5625rem' }}
                    />
                  </>
                )}
                {showNav && (
                  <>
                    <Typography
                      aria-label="close main menu"
                      style={{ visibility: 'hidden' }}
                    >Close
                    </Typography>
                    <IconClose title="close main menu" width={16} height={16} />
                  </>
                )}
              </button>
            </Box>
          </Toolbar>
        </Container>
      </Box>
      <MobileNavigationDrawer
        linksCollection={linksCollection}
        utilityNavigationLinksCollection={utilityNavigationLinksCollection}
        show={showNav}
        ref={ref}
      />
    </AppBar>
  )
}

HamburgerMenuHeader.propTypes = {
  // isHome: PropTypes.bool,
  // hasLiveBanner: PropTypes.bool,
  // image: PropTypes.shape({
  //   url: PropTypes.string,
  // }),
  linksCollection: PropTypes.shape({
    items: PropTypes.array,
  }),
  utilityNavigationLinksCollection: PropTypes.shape({
    items: PropTypes.array,
  }),
}

export default HamburgerMenuHeader

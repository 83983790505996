import PropTypes from 'prop-types'
import { styled, makeStyles, useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useCtflTableRootStyles = makeStyles({
  root: ({ theme, border }) => ({
    marginBottom: theme.spacing(4),
    '& table': {
      borderTop: border ? `1px solid ${theme.palette.grey.light}` : null,
    },
    '& tr:first-child td': {
      paddingTop: 16,
    },
    '& td': {
      borderBottom: 'none',
      verticalAlign: 'text-top',
      '& p': {
        marginBottom: 1,
        marginTop: 0,
      },
      [theme.breakpoints.up('md')]: {
        fontSize: 16,
      },
    },
    '& td:first-child': {
      fontWeight: 'bolder',
      color: theme.palette.primary.main,
      paddingLeft: 0,
    },
    '& td:last-child': {
      paddingRight: 0,
    },
  }),
})

export const CtflTableRoot = (props) => {
  const { children } = props
  const theme = useTheme()
  const classes = useCtflTableRootStyles({ ...props, theme })
  return (
    <div className={`${classes.root}`}>
      {children}
    </div>
  )
}

CtflTableRoot.propTypes = {
  children: PropTypes.node,
}

export const Headline = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}))

import { Query } from '@apollo/client/react/components'
import LinearProgress from '@material-ui/core/LinearProgress'
import Error from '@components/error'
import CtflAsset from './ctfl-asset'
import { assetQuery } from './ctfl-asset.query'

export default function CtflAssetWithData(props) {
  return (
    <Query query={assetQuery} variables={props}>
      {({ loading, error, data }) => {
        if (error) return <Error error={error} />
        if (loading) return <LinearProgress color="secondary" />
        const response = data.asset
        return (
          <CtflAsset {...props} {...response} />
        )
      }}
    </Query>
  )
}

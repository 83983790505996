import React from 'react'
import PropTypes from 'prop-types'
import Link from '@components/link'
import { makeStyles } from '@material-ui/core/styles'
import { IconArrowExternal } from './icons'
import CtaArrow, { CtaText } from './cta-arrow'

export const useAnchorStyles = makeStyles(() => ({
  ctaElement: {
    margin: 0,
  },
  external: {
    position: 'relative',
    top: 13,
    left: 12,
  },
}))

const ButtonLinkArrow = ({ className, item }) => {
  const styles = useAnchorStyles()

  return (
    <div className={className}>
      <Link {...item}>
        <CtaText mt={[2, 3]} className={styles.ctaElement}>
          {item.ctaText || 'Read Story'}
          {item.externalLink ? <IconArrowExternal className={styles.external} /> : <CtaArrow color="black" />}
        </CtaText>
      </Link>
    </div>
  )
}

ButtonLinkArrow.propTypes = {
  item: PropTypes.shape({
    slug: PropTypes.string,
    __typename: PropTypes.string,
    ctaText: PropTypes.string,
    externalLink: PropTypes.string,
  }),
  className: PropTypes.string,
}

export default ButtonLinkArrow

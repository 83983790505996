import useStyles from './skip-to-content.styles'

const SkipToContent = () => {
  const styles = useStyles()

  return (
    <a className={styles.root} href="#main">Skip to main content</a>
  )
}

SkipToContent.propTypes = {
}

SkipToContent.defaultProps = {}

export default SkipToContent

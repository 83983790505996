import { createTheme, responsiveFontSizes } from '@material-ui/core/styles'

const fonts = {
  headline: 'Source Serif Pro',
  eyebrow: 'Roboto Condensed',
  text: 'Nunito Sans',
}

const headlineFont = [
  fonts.headline,
  'serif',
].join(',')

const defaultFont = [
  fonts.text,
  'sans-serif',
].join(',')

const eyebrowFont = [
  fonts.eyebrow,
  'sans-serif',
].join(',')

export { headlineFont, defaultFont, eyebrowFont }

// Font Weights
const headlineWeight = 700
const utilityWeight = 700
const bodyWeight = 300

const primaryColorPalette = {
  light: '#DBD2C1',
  main: '#AB9157',
  dark: '#836F50',
  neutral: '#FAF6EF',
  contrastText: '#FFFFFF',
}

const secondaryColorPalette = {
  light: '#043362',
  main: '#00234B',
  dark: '#001123',
  contrastText: '#FFFFFF',
}

const ryderCupColors = {
  usa: '#C81414',
  intl: '#003C82',
  neutral: '#828282',
}

const globalTheme = createTheme({
  breakpoints: {
    values: {
      xl: 1920, // desktop
      lg: 1440, // laptops
      nb: 1024, // netbooks, some tablets
      md: 960, // tablet
      sm: 600, // mobile
      xs: 0, // no screen?
    },
  },
  palette: {
    accent: {
      main: '#FFBE00',
      red: '#D32F2F',
    },
    secondary: secondaryColorPalette,
    primary: primaryColorPalette,
    ryderCup: ryderCupColors,
    grey: {
      darkest: '#090909',
      darker: '#555555',
      dark: '#6A6A6A',
      light: '#D8D8D8',
      main: '#9E9E9E',
      warm: '#D7D2CB',
    },
    blue: {
      main: '#3268A7',
      light: '#EFF5FB',
      light25: '#F5F7FA',
      light100: '#EBEFF5',
      dark: '#001529',
      medium: '#EBEFF5',
    },
    green: {
      light: '#EDF4EA',
      dark: '#002C21',
    },
    gold: {
      main: '#E6BC5A',
    },
    opaque: {
      white: '#FFFFFFF2',
      blue: '#00234BE6',
    },
    offwhite: {
      main: '#F7F7F7',
    },
    background: {
      default: '#F7F7F7',
      gradient: `
        linear-gradient(180deg,
          rgba(0, 0, 0, 0.25) 0%,
          rgba(0, 0, 0, 0.2) 10%,
          rgba(0, 0, 0, 0.15) 30%,
          rgba(0, 0, 0, 0.5) 50%,
          rgba(0, 0, 0, 0.6) 100%
        )`,
      reverseGradient: `
        linear-gradient(0deg,
          rgba(0, 0, 0, 0) 1.56%,
          rgba(0, 0, 0, 0.648) 100%
        )`,
    },
    text: {
      primary: '#000000',
    },
    shadow: {
      cardBoxShadow: '0px 4px 36px rgba(0, 10, 22, 0.13)',
    },
  },
  shape: {
    borderRadius: 0,
  },
  typography: {
    fontFamily: defaultFont,
    fontSize: 14,
    h1: {
      fontSize: '60px',
      fontFamily: headlineFont,
      fontWeight: headlineWeight,
      letterSpacing: -1.5,
      lineHeight: 1,
    },
    h2: {
      fontSize: '50px',
      fontFamily: headlineFont,
      fontWeight: headlineWeight,
      letterSpacing: -0.5,
      lineHeight: 1.2,
    },
    h3: {
      fontSize: '40px',
      fontFamily: headlineFont,
      fontWeight: headlineWeight,
      letterSpacing: -1,
      lineHeight: 1.3,
    },
    h4: {
      fontSize: '32px',
      fontFamily: headlineFont,
      fontWeight: headlineWeight,
      letterSpacing: -1,
      lineHeight: 1.25,
    },
    h5: {
      fontSize: '24px',
      fontFamily: headlineFont,
      fontWeight: headlineWeight,
      letterSpacing: 0,
      lineHeight: 1.16,
    },
    h6: {
      fontSize: '18px',
      fontFamily: headlineFont,
      fontWeight: headlineWeight,
      letterSpacing: 0,
      lineHeight: 1.3,
    },
    body1: {
      lineHeight: 1.75,
      fontSize: '16px',
      fontWeight: bodyWeight,
    },
    body2: {
      lineHeight: 1.75,
      fontSize: '14px',
      fontWeight: bodyWeight,
    },
    subtitle1: {
      // Subtitle Large
      fontSize: '16px',
      letterSpacing: 0.1,
      lineHeight: '20px',
      fontWeight: utilityWeight,
    },
    subtitle2: {
      // Subtitle Small
      fontSize: '14px',
      letterSpacing: 0.1,
      lineHeight: '20px',
      fontWeight: utilityWeight,
    },
    subtitleXl: {
      fontSize: '18px',
      letterSpacing: 0.1,
      lineHeight: '20px',
      fontWeight: utilityWeight,
    },
    button: {
      fontSize: '14px',
      letterSpacing: 0.1,
    },
    caption: {
      fontSize: '12px',
      letterSpacing: 0.4,
      lineHeight: '16px',
      fontWeight: '600',
    },
    overline: {
      fontSize: '14px',
      letterSpacing: 1.5,
      fontWeight: 'bold',
      fontFamily: eyebrowFont,
      display: 'block',
    },
    cta: {
      fontSize: '16px',
      lineHeight: '20px',
    },
    formLabels: {
      fontSize: '14px',
      lineHeight: '25px',
      fontWeight: utilityWeight,
    },
    formInputs: {
      fontSize: '16px',
      lineHeight: '19px',
      fontWeight: utilityWeight,
    },
    formTags: {
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: utilityWeight,
    },
    cardFilters: {
      fontSize: '12px',
      lineHeight: '16px',
    },
    cardItemCategory: {
      fontFamily: eyebrowFont,
      fontSize: '12px',
      lineHeight: '16px',
      textTransform: 'uppercase',
    },
    navigation: {
      fontSize: '16px',
      fontFamily: headlineFont,
      fontWeight: headlineWeight,
      lineHeight: 1.253125,
    },
  },
  props: {
    MuiCard: {
      elevation: 0,
    },
    MuiButtonBase: {
      disableRipple: true,
    },
  },
})

const theme = createTheme({
  ...globalTheme,
  overrides: {
    MuiButtonBase: {
      root: {
        '&:focus': {
          outline: 'solid',
        },
      },
    },
    MuiButton: {
      root: {
        boxShadow: 'none',
        height: 48,
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '.625rem 1.5625rem',
        fontSize: '1rem',
        lineHeight: 1.75,
        fontFamily: 'Nunito Sans',
        textTransform: 'none',
        fontWeight: 700,
        borderRadius: '50px',
        textDecoration: 'underline',
        textUnderlineOffset: '2px',
        textDecorationColor: 'transparent',
        transition: 'text-decoration-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        '&:hover': {
          textDecoration: 'underline',
        },
        [globalTheme.breakpoints.down('sm')]: {
          height: 40,
          fontSize: '.875rem',
          padding: '.24rem 1.5625rem',
        },
      },
      contained: {
        boxShadow: 'none',
      },
      outlined: {
        padding: '0 72px',
      },
      fullWidth: {
        paddingRight: 16,
        paddingLeft: 16,
      },
      containedPrimary: {
        color: globalTheme.palette.primary.contrastText,
        backgroundColor: globalTheme.palette.blue.main,
        '&:hover': {
          backgroundColor: `${globalTheme.palette.secondary.light}!important`,
          color: globalTheme.palette.primary.contrastText,
          textDecorationColor: globalTheme.palette.primary.contrastText,
        },
      },
      containedSecondary: {
        color: globalTheme.palette.text.primary,
        backgroundColor: 'white',
        '&:hover': {
          backgroundColor: globalTheme.palette.gold.main,
          textDecorationColor: globalTheme.palette.text.primary,
        },
      },
      sizeSmall: {
        height: 40,
        fontSize: '.875rem',
        padding: '.24rem 1.5625rem',
      },
      containedSizeSmall: {
        padding: '.24rem 1.5625rem',
      },
      textSizeSmall: {
        padding: '.24rem 1.5625rem',
      },
      text: {
        color: globalTheme.palette.blue.main,
        padding: '.625rem 1.5625rem',
        borderRadius: 0,
        '&:hover': {
          color: globalTheme.palette.secondary.light,
          backgroundColor: 'transparent',
        },
      },
      textPrimary: {
        padding: '.625rem 1.5625rem',
        color: globalTheme.palette.blue.main,
        '&:hover': {
          color: globalTheme.palette.secondary.light,
          backgroundColor: 'transparent',
        },
      },
      outlinedPrimary: {
        padding: '.625rem 1.5625rem',
        color: globalTheme.palette.primary.contrastText,
        borderColor: globalTheme.palette.primary.contrastText,
        '&:hover': {
          backgroundColor: globalTheme.palette.primary.contrastText,
          borderColor: globalTheme.palette.primary.contrastText,
          textDecorationColor: 'currentColor',
          color: 'black',
        },
      },
      outlinedSecondary: {
        padding: '.625rem 1.5625rem',
        color: globalTheme.palette.secondary.main,
        borderColor: globalTheme.palette.secondary.main,
        '&:hover': {
          backgroundColor: globalTheme.palette.secondary.contrastText,
          borderColor: globalTheme.palette.secondary.contrastText,
          textDecorationColor: 'currentColor',
          color: 'black',
        },
      },
    },
    MuiTableCell: {
      root: {
        padding: 8,
      },
    },
    MuiBreadcrumbs: {
      separator: {
        color: '#9e9e9e',
      },
    },
    MuiAvatar: {
      root: {
        height: 110,
        width: 110,
      },
    },
    MuiPickersDay: {
      current: {
        color: '#000000',
        '& .MuiIconButton-label p': {
          color: '#000000',
          fontWeight: '600',
        },
        '&.MuiPickersDay-dayDisabled .MuiIconButton-label p': {
          color: 'inherit',
        },
        '&.MuiPickersDay-daySelected .MuiIconButton-label p': {
          color: '#FFFFFF',
        },
      },
      daySelected: {
        backgroundColor: secondaryColorPalette.main,
        '&:hover': {
          backgroundColor: secondaryColorPalette.main,
        },
      },
    },
  },
})

export default responsiveFontSizes(theme)

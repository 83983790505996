import gql from 'graphql-tag'
import { Query } from '@apollo/client/react/components'
import LinearProgress from '@material-ui/core/LinearProgress'
import Error from '@components/error'
import Pullquote from './pullquote'

export const fragment = `
  text
  attribution
  hideQuote
`

const QUERY = gql`
query PullQuote($id: String!) {
  pullQuote(id: $id) {
    ${fragment}
  }
}
`

export default function PullquoteWithData(props) {
  return (
    <Query query={QUERY} variables={props}>
      {({
        loading, error, data,
      }) => {
        if (error) return <Error error={error} />
        if (loading) return <LinearProgress color="secondary" />
        const response = data.pullQuote
        return (
          <Pullquote {...props} {...response} />
        )
      }}
    </Query>
  )
}

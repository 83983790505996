import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  footer: {
    backgroundColor: theme.palette.grey.darkest,
    zIndex: 2,
    position: 'relative',
  },
  linksContainer: {
    order: 1,
    [theme.breakpoints.down('sm')]: {
      order: 2,
    },
  },
  navGroupContainer: {
    order: 2,
    [theme.breakpoints.down('sm')]: {
      order: 1,
    },
  },
  logo: {
    display: 'inline-block',
    marginBottom: theme.spacing(4),
    '& svg': {
      color: theme.palette.common.white,
    },
    '&:hover svg': {
      fill: theme.palette.primary.main,
    },
  },
  emailForm: {
    maxWidth: 300,
  },
  emailHeadline: {
    color: theme.palette.common.white,
    marginBottom: theme.spacing(2),
  },
  aux: {
    position: 'relative',
    display: 'block',
    color: theme.palette.common.white,
    lineHeight: '1.125rem',
    [theme.breakpoints.up('md')]: {
      display: 'inline-block',
      paddingLeft: theme.spacing(4),
      '&:not(:first-of-type)': {
        '&:before': {
          content: '""',
          position: 'absolute',
          height: '100%',
          width: '1px',
          background: theme.palette.common.white,
          left: theme.spacing(2),
        },
      },
    },
  },
  mobileLeft: {
    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-start',
    },
  },
  copyright: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
    },
  },
  hideMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  mission: {
    color: theme.palette.common.white,
    marginBottom: '3rem',
    maxWidth: '18.75rem',
  },
  navGroup: {
    paddingRight: '1rem',
    '& .MuiTypography-overline': {
      ...theme.typography.navigation,
      color: theme.palette.primary.main,
      marginBottom: theme.spacing(2),
      textTransform: 'capitalize',
      letterSpacing: 'normal',
    },
    '& a': {
      color: theme.palette.common.white,
      lineHeight: 1.25,
      marginBottom: theme.spacing(2),
      fontWeight: 600,
      '&:hover': {
        textDecoration: 'underline',
        textUnderlineOffset: '2px',
      },
    },
  },
  social: {
    display: 'flex',
    gap: '.75rem',
    [theme.breakpoints.down('xs')]: {
      gap: '0',
      justifyContent: 'space-between',
    },
  },
  socialHeadline: {
    display: 'block',
    color: theme.palette.common.white,
    fontSize: '1rem',
    marginBottom: '1.25rem',
  },
  headline: {
    marginBottom: '.3125rem',
  },
  socialLink: {
    height: '2.5rem',
    width: '2.5rem',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
    textAlign: 'center',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    '&:focus': {
      outline: '3px solid white',
    },
  },
  socialIcon: {
    color: theme.palette.common.white,
    width: 16,
    margin: '0 auto',
  },
  utilityContainer: {
    color: theme.palette.common.white,
  },
  utilityFlexContainer: {
    gap: '1.5rem',
    [theme.breakpoints.down('sm')]: {
      gap: '2.5rem',
      flexDirection: 'column',
    },
  },
  partnerContainer: {
    display: 'flex',
    gap: '1.25rem',
    alignItems: 'center',
    justifyContent: 'center',
    order: 3,
    [theme.breakpoints.down('md')]: {
      order: 3,
    },
    [theme.breakpoints.down('sm')]: {
      order: 1,
      flexDirection: 'column',
    },
  },
  utilityLinksContainer: {
    order: 2,
    [theme.breakpoints.up('lg')]: {
      flex: 1,
    },
    [theme.breakpoints.down('md')]: {
      order: 1,
      display: 'flex',
      justifyContent: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      flex: 1,
      order: 2,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '.9375rem',
    },
  },
  copyrightContainer: {
    order: 1,
    [theme.breakpoints.down('md')]: {
      order: 2,
      flexGrow: 1,
    },
    [theme.breakpoints.down('sm')]: {
      order: 3,
      flexGrow: 1,
      textAlign: 'center',
    },
  },
  navContainer: {
    gap: `${theme.spacing(2)}px`,
    [theme.breakpoints.up('md')]: {
      gap: `${theme.spacing(3)}px`,
    },
  },
}))

import gql from 'graphql-tag'
import { Query } from '@apollo/client/react/components'
import LinearProgress from '@material-ui/core/LinearProgress'
import Error from '@components/error'
import ContentPromoBlock from './content-promo-block'

export const promoBlockFragment = `
  headline
  subtitle
  internalLink
  externalLink
  linkText
  slug
`

export const PromoBlockBySlugQuery = gql`
  query BySlug($slug: String) {
    contentPromoBlockCollection(limit: 1, where: {slug: $slug}) {
    items {
      ${promoBlockFragment}
    }
  }
}
`

const promoBlockByIdQuery = gql`
query ContentPromoBlock($id: String!) {
  contentPromoBlock(id: $id) {
    ${promoBlockFragment}
  }
}
`

export default function ContentPromoBlockWithData(props) {
  return (
    <Query query={promoBlockByIdQuery} variables={props}>
      {({
        loading, error, data,
      }) => {
        if (error) return <Error error={error} />
        if (loading) return <LinearProgress color="secondary" />
        const response = data.contentPromoBlock
        return (
          <ContentPromoBlock {...response} {...props} />
        )
      }}
    </Query>
  )
}

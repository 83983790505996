/* eslint-disable camelcase */
const createTags = (props, type) => {
  const {
    object_type,
    headline,
    cat,
    category,
    series,
    name,
    tourName,
  } = props
  const tags = {}
  tags['data-gtm-content-type'] = type
  if (headline) tags['data-gtm-headline'] = headline
  if (cat) tags['data-gtm-category'] = cat.series || cat.category

  if (category) tags['data-gtm-category'] = category // archive during search
  if (object_type && series) tags['data-gtm-category'] = series // story during search

  if (type === 'Coach' && name) tags['data-gtm-coach-name'] = name
  if (type === 'Tour' && tourName) tags['data-gtm-tour-name'] = tourName
  if ((type === 'Facility' || type === 'AlgoliaFacility') && name) tags['data-gtm-facility'] = name

  return tags
}

export default createTags

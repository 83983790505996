import PropTypes from 'prop-types'
import Container from '@material-ui/core/Container'
import Link from '@components/link'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import YouTubeIcon from '@material-ui/icons/YouTube'
import FacebookIcon from '@material-ui/icons/Facebook'
import InstagramIcon from '@material-ui/icons/Instagram'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import NavGroup from '@components/nav-group'
import { Image } from '@components/atoms'
import { IconTikTokLogo, XIcon } from '@components/atoms/icons'
import { useStyles } from './footer.styles'

const icons = {
  instagram: InstagramIcon,
  youtube: YouTubeIcon,
  linkedin: LinkedInIcon,
  x: XIcon,
  facebook: FacebookIcon,
  tiktok: IconTikTokLogo,
}

const Footer = (props) => {
  const {
    copyright,
    companyTitle,
    companyMission,
    auxilliaryCollection,
    linksCollection,
    socialCollection,
    partnerDescription,
    partnerLogo,
  } = props

  const s = useStyles()
  const auxItems = auxilliaryCollection ? auxilliaryCollection.items : []
  const navGroups = linksCollection ? linksCollection.items : []
  const renderAux = {
    Page: aux => (
      <Link {...aux} key={aux.slug} className={s.aux}>{aux.headline} </Link>
    ),
    NavLink: aux => (
      <Link {...aux} key={aux.link} className={s.aux}>{aux.text} </Link>
    ),
  }

  const logo = '/images/logo-white.png'

  return (
    <Box className={s.footer} pt={[6, 6, 9]} data-cy="footer">
      <Container>
        <Grid container className={s.navContainer}>
          <Grid item xs={12} sm={8} md={4} className={s.linksContainer}>
            <Link link="/" className={s.logo}>
              <Image src={logo} alt="PGA Home Page" width={44} />
            </Link>
            <Typography variant="h5" color="primary" className={s.headline}>
              {companyTitle}
            </Typography>
            <Typography className={s.mission}>
              {companyMission}
            </Typography>
            {socialCollection
                && socialCollection.items && (
              <Box>
                <Typography variant="h5" className={s.socialHeadline}>
                    Follow Us
                </Typography>
                <div className={s.social}>
                  {socialCollection.items.map(({ headline, link }) => {
                    const iconName = headline.toLowerCase()
                    const Icon = icons[iconName] || YouTubeIcon
                    return (
                      <a target="_blank" rel="noopener noreferrer" className={s.socialLink} href={link} key={headline}>
                        <Icon className={s.socialIcon} titleAccess={headline} />
                      </a>
                    )
                  })}
                </div>
              </Box>
            )}
          </Grid>
          <Grid container item className={s.navGroupContainer} md lg>
            {navGroups.map(group => (
              <Grid item xs={6} sm={3} key={group.headline}>
                <NavGroup {...group} className={s.navGroup} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Container>
      <Box className={s.utilityContainer} mt={4} py={4} pb={props.gdprBannerVisible ? 20 : 4} bgcolor="secondary.dark">
        <Container>
          <Grid container className={s.utilityFlexContainer}>
            <Grid item className={s.copyrightContainer}><span className={`${s.copyright}`}>{copyright}</span></Grid>
            <Grid item xs={12} md={12} lg className={s.utilityLinksContainer}>
              {auxItems.map(aux => (
                renderAux[aux.__typename](aux)
              ))}
            </Grid>
            {partnerDescription || partnerLogo
              ? (
                <Grid item className={s.partnerContainer}>
                  {partnerDescription
                    && <span>{partnerDescription}</span>
                  }
                  {partnerLogo
                    && <Image src={`${partnerLogo.url}?fl=progressive&q=80&w=81&h=25`} alt={partnerLogo.description || 'CapTech'} width={81} />
                  }
                </Grid>
              )
              : null
            }
          </Grid>
        </Container>
      </Box>
    </Box>
  )
}

Footer.propTypes = {
  copyright: PropTypes.string,
  companyTitle: PropTypes.string,
  companyMission: PropTypes.string,
  partnerDescription: PropTypes.string,
  partnerLogo: PropTypes.shape({
    url: PropTypes.string,
    description: PropTypes.string,
  }),
  gdprBannerVisible: PropTypes.bool,
  auxilliaryCollection: PropTypes.shape({
    items: PropTypes.array,
  }),
  linksCollection: PropTypes.shape({
    items: PropTypes.array,
  }),
  socialCollection: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({
      headline: PropTypes.string,
      link: PropTypes.string,
    })),
  }),
}

export default Footer

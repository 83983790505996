import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import useCookie from '@hooks/useCookie'
import useStyles from './live-banner.styles'

const LiveBanner = (props) => {
  const {
    videoId,
    setLiveBanner,
    liveBannerText,
    liveVideoOverride,
    hasHero,
  } = props

  const pollLiveVideoStatusInterval = 60000 // 1min
  const inTwoHours = new Date(new Date().getTime() + 2 * 60 * 60 * 1000)
  const [hideBanner, setHideBanner] = useCookie('pga_dismissLiveBanner', { expires: inTwoHours })
  const [isLive, setIsLive] = useState(false)
  const [intervalId, setIntervalId] = useState(null)
  const styles = useStyles({ hasHero })

  const apiGatewayUrl = `${process.env.BASE_API_GATEWAY}channel?id=`

  const handleDismiss = () => {
    setHideBanner(true)
    setLiveBanner(false)
    setIsLive(false)
    clearInterval(intervalId)
  }

  useEffect(() => {
    async function fetchData() {
      let isLiveVideo = false
      try {
        const response = await fetch(apiGatewayUrl + videoId, {
          cache: 'no-store',
        })
        const jsonResp = await response.json()
        isLiveVideo = !!(jsonResp?.isLive)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(`get YouTube channel ${videoId} status error`, e)
      }
      setIsLive(isLiveVideo || liveVideoOverride)
      setLiveBanner(isLiveVideo || liveVideoOverride)
    }
    if (!hideBanner) {
      clearInterval(intervalId)
      const timerId = setInterval(() => fetchData(), pollLiveVideoStatusInterval)
      setIntervalId(timerId)
      fetchData()
    }
  }, [videoId])

  return isLive && !hideBanner ? (
    <Box p={1} className={styles.root}>
      <Container className={styles.container}>
        <Typography variant="body1">{liveBannerText}</Typography>
        <Box className={styles.actionWrapper}>
          <Button
            className={styles.actionHide}
            disableRipple
            onClick={handleDismiss}
          >
            Dismiss
          </Button>
          <Button
            variant="contained"
            size="small"
            className={styles.action}
            disableRipple
            href="/iconic-insights"
          >
            Watch Live
          </Button>
        </Box>
      </Container>
    </Box>
  ) : null
}

LiveBanner.propTypes = {
  videoId: PropTypes.string,
  liveBannerText: PropTypes.string,
  setLiveBanner: PropTypes.func,
  liveVideoOverride: PropTypes.bool,
  hasHero: PropTypes.bool,
}

export default LiveBanner

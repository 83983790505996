import { useState, useEffect } from 'react'

const useClickOutside = (ref) => {
  const [clickedOutside, setClickOutside] = useState(false)

  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      setClickOutside(false)
    } else {
      setClickOutside(true)
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  })

  return clickedOutside
}

export default useClickOutside

import gql from 'graphql-tag'
import { Query } from '@apollo/client/react/components'

import LinearProgress from '@material-ui/core/LinearProgress'
import { memo } from 'react'
import { DateTime } from 'luxon'
import Error from '@components/error'
import GlobalEventAlert from './global-event-alert'

export const GET_GLOBAL_EVENT_ALERT_QUERY = gql`
query GlobalEventAlert($now: DateTime) {
  globalEventAlertCollection(
    limit: 1
    where: {
      AND: [
        { startDateTime_lte: $now }
        { endDateTime_gte: $now }
      ]
    }
  ) {
    items {
      startDateTime
      endDateTime
      ctaText
      ctaUrl
      headline
    }
  }
}
`

function GlobalEventAlertWithData() {
  const today = DateTime.local().startOf('day').toISO()

  return (
    <Query query={GET_GLOBAL_EVENT_ALERT_QUERY} variables={{ now: today }}>
      {({
        loading, error, data,
      }) => {
        if (error) return <Error error={error} />
        if (loading) return <LinearProgress color="secondary" />

        const response = data && data.globalEventAlertCollection
        && data.globalEventAlertCollection.items
        && data.globalEventAlertCollection.items[0]

        return response ? (
          <GlobalEventAlert item={response} />
        ) : <></>
      }}
    </Query>
  )
}

GlobalEventAlertWithData.propTypes = {
}

export default memo(GlobalEventAlertWithData)

import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import React from 'react'
import useStyles from './content-promo-block.styles'

const ContentPromoBlock = (props) => {
  const {
    headline,
    subtitle,
    externalLink,
    internalLink,
    linkText,
    embed,
  } = props

  const styles = useStyles({ embed })
  const link = internalLink || externalLink || '/coaches/search'

  return (
    <Box className={styles.root} data-testid="root">
      <Typography variant="h4">{headline}</Typography>
      <Box className={styles.description}>
        <Typography variant="subtitle2">{subtitle}</Typography>
      </Box>
      <Button
        variant="contained"
        size="small"
        className={styles.linkButton}
        href={link}
      >
        {linkText}
      </Button>
    </Box>
  )
}

ContentPromoBlock.propTypes = {
  headline: PropTypes.string,
  subtitle: PropTypes.string,
  externalLink: PropTypes.string,
  internalLink: PropTypes.string,
  linkText: PropTypes.string,
  embed: PropTypes.bool,
}

export default ContentPromoBlock

import PropTypes from 'prop-types'
import Link from '@components/link'
import Typography from '@material-ui/core/Typography'

import useStyles from './nav-group.styles'

const NavGroup = (props) => {
  const {
    className,
    headline: header,
    linksCollection: {
      items,
    },
  } = props

  const s = useStyles()

  return (
    <div className={`${s.root} ${className}`}>
      <Typography color="primary" variant="overline">{header}</Typography>
      {items.map((item) => {
        const {
          link,
          externalLink,
          slug,
          text,
          headline,
          tourName,
        } = item

        return (
          <Link
            {...item}
            key={slug || link || externalLink}
            className={s.subnavLink}
            anchorProps={{'data-gtm-sub-nav-item': slug || link || externalLink}}
          >
            {tourName || headline || text}
          </Link>
        )
      })}
    </div>
  )
}

NavGroup.propTypes = {
  className: PropTypes.string,
  headline: PropTypes.string,
  linksCollection: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({
      __typename: PropTypes.string,
      slug: PropTypes.string,
      link: PropTypes.string,
      externalLink: PropTypes.string,
    })),
  }),
}

NavGroup.defaultProps = {}

export default NavGroup

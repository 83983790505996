import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import Link from 'next/link'
import Box from '@material-ui/core/Box'
import AppBar from '@material-ui/core/AppBar'
import Container from '@material-ui/core/Container'
import HamburgerMenuHeader from '@components/header/hamburger-menu/header'
import Router from 'next/router'
import PGALogo from '@components/atoms/icons/pga-logo'
import NoSsr from '@material-ui/core/NoSsr'


import useClickOutside from '@hooks/useClickOutside'
import useStyles from './header.styles'
import DesktopNavigationDrawer from './desktop-navigation-drawer'
import NavButton from './nav-button'
import DesktopUtilityNav from './desktop-utility-nav'
import { GlobalEventAlertQuery } from '../common/global-event-alert/index'
import SkipToContent from '../common/skip-to-content/index'

const HorizontalMenuHeader = (props) => {
  const {
    linksCollection,
    utilityNavigationLinksCollection,
  } = props
  const s = useStyles()

  const [showDrawer, setShowDrawer] = useState(false)
  const [drawerEntered, setDrawerEntered] = useState(false)
  const [content, setContent] = useState()
  const hasLinks = linksCollection && linksCollection.total > 0

  const handleToggleDrawer = () => {
    setShowDrawer(!showDrawer)
  }

  const handleButtonClick = (selectedLink) => {
    if (selectedLink === content) {
      setShowDrawer(!showDrawer)
    } else {
      setShowDrawer(true)
    }
    setContent(selectedLink)
  }

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (showDrawer) {
      // Prevent scrolling while drawer is open.
      if (process.browser) {
        const originalStyle = window.getComputedStyle(document.body).overflow
        document.body.style.overflow = 'hidden'
        return () => {
          document.body.style.overflow = originalStyle
        }
      }
    } else {
      setDrawerEntered(false)
    }
  }, [showDrawer])

  const handleOnSidebarEntered = () => {
    setDrawerEntered(true)
  }

  const handleCloseNav = () => {
    setShowDrawer(false)
  }

  // Close drawer on navigation.
  useEffect(() => {
    Router.events.on('routeChangeStart', handleCloseNav)
    return () => {
      Router.events.off('routeChangeStart', handleCloseNav)
    }
  }, [])

  const ref = useRef(null)

  const clickedOutside = useClickOutside(ref)

  useEffect(() => {
    if (showDrawer) { handleCloseNav() }
  }, [clickedOutside])

  return (
    <>
      <AppBar position="sticky" className={s.root}>
        <NoSsr><SkipToContent /></NoSsr>
        <NoSsr><GlobalEventAlertQuery /></NoSsr>
        <DesktopUtilityNav utilityNavigationLinksCollection={utilityNavigationLinksCollection} />
        <nav className={`${s.nav}`}>
          <Container style={{ height: '100%' }}>
            <Box height="100%">
              <div className={s.shadow} />
              <Box ref={ref} display="flex" alignItems="stretch" justifyContent="space-between" height="100%">
                <Link href="/">
                  <a title="PGA Home Page" className={s.logo} data-gtm-nav-item="main">
                    <PGALogo />
                  </a>
                </Link>
                {hasLinks && linksCollection.items.map((link) => {
                  const hasSubNavigation = link.subNavigationCollection
                  && !!link.subNavigationCollection?.items?.length
                  const subNavigationLinkCount = hasSubNavigation
                    ? link.subNavigationCollection.items[0].linksCollection?.items?.length + 1
                    : 1
                  return (
                    <Box key={link.sys.id} display="flex">
                      <NavButton
                        data-testid={`navbutton-${link.text}`}
                        onClick={() => handleButtonClick(link)}
                        open={showDrawer && link === content}
                        aria-label={`${link.text}, Menu with ${subNavigationLinkCount} items, ${showDrawer && link === content ? 'open' : 'closed'}`}
                        data-gtm-nav-item="main"
                      >{link.text}
                      </NavButton>
                      <DesktopNavigationDrawer
                        selectedLink={link}
                        showSlide={showDrawer && link === content}
                        toggleDrawer={handleToggleDrawer}
                        drawerEntered={drawerEntered}
                        onEntered={handleOnSidebarEntered}
                      />
                    </Box>
                  )
                })}
              </Box>
            </Box>
          </Container>
        </nav>
      </AppBar>
    </>
  )
}

HorizontalMenuHeader.propTypes = {
  linksCollection: PropTypes.shape({
    total: PropTypes.number,
    items: PropTypes.array,
  }),
  utilityNavigationLinksCollection: PropTypes.shape({
    items: PropTypes.array,
  }),
}

const Header = (props) => {
  return (
    <>
      <HorizontalMenuHeader {...props} />
      <HamburgerMenuHeader {...props} />
    </>
  )
}

export default Header

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  card: {
    display: 'block',
    '& > *': {
      borderRadius: 4,
      border: `1px solid ${theme.palette.divider}`,
    },
    '&:hover, & a:hover': {
      boxShadow: '0px 4px 16px rgba(0,0,0,0.06)',
      color: '#000',
      textDecoration: 'none',
    },
  },
  fancyLink: {
    textDecoration: 'none',
    boxShadow: `0 2px 0 ${theme.palette.primary.main}`,
    color: theme.palette.grey[600],
    '&:hover': {
      backgroundColor: 'rgba(171,	145,	87, 0.25)',
      boxShadow: 'none',
      color: theme.palette.primary.dark,
    },
  },
  noBorder: {
    '& > *': {
      borderRadius: 4,
      border: 'none',
    },
  },
}))

export default useStyles

import { CtaText } from '@components/atoms/cta-arrow'
import Link from '@components/link'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'


import PropTypes from 'prop-types'
import HeaderFeaturedStory from '../common/header-featured-story-list/header-featured-story/header-featured-story'
import useStyles from './desktop-navigation-drawer-menu.styles'
import { HeaderFeaturedStoryListQuery } from '../common/header-featured-story-list'
import { useFeaturedStories } from '../hooks/useFeaturedStories'

export default function DesktopNavigationDrawerMenu({ link }) {
  const styles = useStyles()
  const hasSubNavigation = link.subNavigationCollection
    && !!link.subNavigationCollection?.items?.length

  const {
    hasFeaturedStories,
    showFeaturedStoriesByCategory,
    featuredStoryCategoryIds,
    featuredStoryQueryLimit,
  } = useFeaturedStories(link)

  return (
    <Grid container className={styles.root} spacing={3} direction={hasSubNavigation ? 'row' : 'column'}>
      <Grid item xs={hasSubNavigation ? 6 : 12}>
        <Link {...link} anchorProps={{ 'data-gtm-nav-item': 'primary' }} className={styles.headlineLink}>
          <CtaText variant="h2" className={styles.headline}>
            {link.headline || link.text}
          </CtaText>
        </Link>
        <Box mb={4} maxWidth="400px">
          <Typography variant="body1" color="textPrimary">
            {link.description}
          </Typography>
        </Box>
        {hasSubNavigation
      && (
        <ul style={{ padding: 0, margin: 0, listStyle: 'none' }}>
          {link.subNavigationCollection.items[0].linksCollection?.items.map((item) => {
            const {
              link: itemLink,
              externalLink,
              slug,
              text,
              headline,
              tourName,
            } = item

            return (
              <li key={item.sys.id} className={styles.link}>
                <Link {...item} anchorProps={{ 'data-gtm-nav-item': 'secondary' }} className={styles.subNavLink}>
                  <CtaText variant="h3" className={styles.subNav}>
                    {tourName || headline || text}
                  </CtaText>
                </Link>
              </li>
            )
          })}
        </ul>
      )}
      </Grid>
      <Grid item container spacing={3} xs={hasSubNavigation ? 6 : 12} direction={hasSubNavigation ? 'column' : 'row'}>
        {hasFeaturedStories && link.featuredStoriesCollection?.items?.map(item => (
          <Grid key={item.sys.id} item xs style={{ flexGrow: 0.5 }}>
            <HeaderFeaturedStory item={item} />
          </Grid>
        ))}
        {showFeaturedStoriesByCategory && (
          <HeaderFeaturedStoryListQuery
            categoryIds={featuredStoryCategoryIds}
            containerProps={{ item: true, xs: true, style: { flexGrow: 0.5 } }}
            limit={featuredStoryQueryLimit}
          />
        )}
      </Grid>
    </Grid>
  )
}

DesktopNavigationDrawerMenu.propTypes = {
  link: PropTypes.object,
}

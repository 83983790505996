import UtilityNavLink from '@components/header/common/utility-nav-link/index'
import Box from '@material-ui/core/Box'
import PropTypes from 'prop-types'
import useStyles from './mobile-utility-nav.styles'

const MobileUtilityNav = (props) => {
  const {
    utilityNavigationLinksCollection,
  } = props
  const styles = useStyles()

  return (
    <Box className={styles.root} display="flex" flexDirection="column">
      {utilityNavigationLinksCollection.items?.map(item => (
        <UtilityNavLink key={item.sys.id} link={item} className={styles.item} />
      ))}
    </Box>
  )
}

MobileUtilityNav.propTypes = {
  utilityNavigationLinksCollection: PropTypes.shape({
    items: PropTypes.array,
  }),
}

MobileUtilityNav.defaultProps = {}

export default MobileUtilityNav

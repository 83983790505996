const richTextFragment = `
  json
  links {
    entries {
      hyperlink {
        __typename
        ... on Story {
          slug
          sys {
            id
          }
        }
        ... on Event {
          slug
          sys {
            id
          }
        }
        ... on Tour {
          slug
          sys {
            id
          }
        }
        ... on Page {
          slug
          sys {
            id
          }
        }
      }
      inline {
        __typename
        ... on Story {
          slug
        }
        ... on Event {
          slug
        }
      }
      block {
        __typename
        sys {
          id
        }
      }
    }
  }
`

export default richTextFragment

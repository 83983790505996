import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: '900px',
    margin: 'auto',
  },
  headline: {
    color: theme.palette.secondary.light,
    marginBottom: '.625rem',
    ...theme.typography.h4,
  },
  headlineLink: {
    display: 'block',
  },
  link: {
    fontSize: '1.125rem',
    marginBottom: '1.875rem',
    lineHeight: '1.4375rem',
  },
  subNav: theme.typography.h6,
  subNavLink: {
    display: 'block',
  },
}))

export default useStyles

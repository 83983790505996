import React from 'react'
import PropTypes from 'prop-types'
import { EmbedInlineStoryQuery } from '@components/embed-inline-story'

const RenderInlineEmbeddedEntry = (props) => {
  // Get Contentful entry by ID and define entry type
  return (
    <EmbedInlineStoryQuery {...props} />
  )
}

RenderInlineEmbeddedEntry.propTypes = {
  id: PropTypes.string,
  linkType: PropTypes.string,
  type: PropTypes.string,
}

export default RenderInlineEmbeddedEntry

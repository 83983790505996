import fetch from 'node-fetch'

const apiGatewayUrl = `${process.env.BASE_API_GATEWAY}redirects?url=`

const redirects = async (path = '', referer = '') => {
  const response = await fetch(`${apiGatewayUrl + path}&referer=${encodeURIComponent(referer)}`)
  return response.json()
}

export default redirects

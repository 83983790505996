import { useEffect, useState } from 'react'
import useCookie from './useCookie'

const useTransformLink = (link) => {
  const [userPosition] = useCookie('pga_userLocation')
  const [transformedLink, setTransformedLink] = useState(link)

  useEffect(() => {
    if (userPosition && link?.includes('refinementList')) {
      const { latitude, longitude } = JSON.parse(userPosition)
      setTransformedLink(`${link}&aroundLatLng=${encodeURIComponent(`${latitude}, ${longitude}`)}`)
    }
  }, [userPosition])
  return transformedLink
}

export default useTransformLink

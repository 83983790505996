import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import useStyles from './header-featured-story-list.styles'
import HeaderFeaturedStory from './header-featured-story/header-featured-story'

const HeaderFeaturedStoryList = (props) => {
  const {
    items,
    containerProps,
  } = props
  const styles = useStyles()

  return (
    <>
      {items.map(item => (
        <Grid key={item.sys.id} {...containerProps}>
          <HeaderFeaturedStory item={item} />
        </Grid>
      ))}
    </>
  )
}

HeaderFeaturedStoryList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    __typename: PropTypes.oneOf(['Story']),
    headline: PropTypes.string,
    image: PropTypes.shape({
      url: PropTypes.string,
    }),
    sys: PropTypes.shape({
      id: PropTypes.string,
    }),
  })),
  containerProps: PropTypes.object,
}

HeaderFeaturedStoryList.defaultProps = {}

export default HeaderFeaturedStoryList

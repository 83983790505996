import { CtaText } from '@components/atoms/cta-arrow'
import Link from '@components/link/index'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import PropTypes from 'prop-types'
import Image from '@components/atoms/image'
import useHover from '@hooks/useHover'
import useStyles from './header-featured-story.styles'

export default function HeaderFeaturedStory(props) {
  const { item } = props
  const [hoverRef, isHovered] = useHover()
  const styles = useStyles()

  const ctaText = item.__typename === 'Event' ? 'View Event' : 'Read More'

  return (
    <Link anchorProps={{ ref: hoverRef, 'data-gtm-nav-item': 'tertiary' }} {...item} className={styles.root} title={item.headline}>
      <Box
        className={styles.container}
        height="100%"
        position="relative"
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
      >
        { item.image?.url && <Image className={styles.image} style={{ width: '100%', height: 'auto' }} src={`${item.image.url}?h=295&w=600&fit=fill`} alt={`image for ${item.headline}` || 'headline Image'}/> }
        <Box bgcolor="common.white" className={styles.content} display="flex" flexDirection="column">
          <Typography variant="h6" className={styles.headline}>{item.headline}</Typography>
          <CtaText hovered={isHovered} variant="subtitle2" className={styles.cta}>
            {ctaText}
          </CtaText>
        </Box>
      </Box>
    </Link>
  )
}

HeaderFeaturedStory.propTypes = {
  item: PropTypes.shape({
    __typename: PropTypes.oneOf(['Story', 'Event', 'Slide']),
    headline: PropTypes.string,
    image: PropTypes.shape({
      url: PropTypes.string,
    }),
    slug: PropTypes.string,
    link: PropTypes.string,
    externalLink: PropTypes.string,
  }),
}

import React from 'react'
import PropTypes from 'prop-types'
import RenderRichText from '@components/renderers/render-rich-text'

const Banner = (props) => {
  const { gdprText } = props
  return <RenderRichText {...gdprText} />
}

Banner.propTypes = {
  gdprText: PropTypes.shape({}),
  text: PropTypes.string,
}

export default Banner

const getTypeFromLinks = (links) => {
  const blocks = {}
  links.entries.block.forEach((block) => {
    blocks[block.sys.id] = block.__typename
  })
  // eslint-disable-next-line no-unused-expressions
  links.entries.hyperlink?.forEach((link) => {
    blocks[link.sys.id] = {
      slug: link.slug,
      type: link.__typename,
    }
  })
  return blocks
}

export default getTypeFromLinks

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  root: {
    gap: '1.875rem',
  },
  item: {
    marginLeft: 0,
  },
}))

export default useStyles

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.accent.main,
    paddingTop: theme.typography.pxToRem(7.5),
    paddingBottom: theme.typography.pxToRem(7.5),
    zIndex: 20,
  },
  link: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 700,
    textDecoration: 'underline',
  },
  headline: {
    fontSize: theme.typography.pxToRem(12),
    position: 'relative',
    fontWeight: 300,
    paddingLeft: theme.typography.pxToRem(30),
    lineHeight: 1.25,
    '&:before': {
      content: '""',
      width: '1px',
      height: '100%',
      backgroundColor: 'currentColor',
      position: 'absolute',
      left: theme.typography.pxToRem(15),
    },
  },
}))

export default useStyles

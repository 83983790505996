import PropTypes from 'prop-types'
import useStyles from './nav-button.styles'

export default function NavButton({ children, open, ...props }) {
  const styles = useStyles()
  return (
    <button type="button" className={`${styles.root} ${open ? 'open' : ''}`} {...props}>{children}</button>
  )
}

NavButton.propTypes = {
  open: PropTypes.bool,
  children: PropTypes.node,
}

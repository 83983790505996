import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: ({ embed }) => ({
    background: embed ? 'rgba(131, 111, 80, 0.08)' : 'rgba(0, 35, 75, 0.08)',
    color: embed ? '#836F50' : theme.palette.secondary.main,
    marginTop: theme.spacing(4),
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(2),
    },
  }),
  description: {
    margin: '16px 120px',
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.54)',
    [theme.breakpoints.down('md')]: {
      margin: '12px 100px',
    },
    [theme.breakpoints.only('xs')]: {
      margin: '8px 0px',
    },
  },
  linkButton: ({ embed }) => ({
    opacity: 0.95,
    borderRadius: '4px',
    background: embed ? '#836F50' : theme.palette.secondary.light,
    '& .MuiButton-label': {
      color: theme.palette.secondary.contrastText,
    },
    '&.MuiButton-root': {
      '&:hover': {
        backgroundColor: embed ? '#836F50' : theme.palette.secondary.light,
        opacity: 1,
      },
    },
  }),
}))

export default useStyles

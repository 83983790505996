import { makeStyles } from '@material-ui/core/styles'

const bottomOffset = '50px'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    height: `calc(100vh - 100% - ${bottomOffset})`,
    left: 0,
    right: 0,
    bottom: `calc(-1 * 100vh + 100% + ${bottomOffset})`,
    backgroundColor: theme.palette.offwhite.main,
    zIndex: 1,
    overflow: 'auto',
  },
}))

export default useStyles

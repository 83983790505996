import PropTypes from 'prop-types'

const DateRange = ({ startDate, endDate }) => {
  const start = new Date(startDate)
  const end = new Date(endDate)

  const startMonth = start.toLocaleDateString('en-us', { month: 'short' })
  const startDay = start.toLocaleDateString('en-us', { day: 'numeric' })
  const startYear = start.toLocaleDateString('en-us', { year: 'numeric' })
  const endMonth = end.toLocaleDateString('en-us', { month: 'short' })
  const endDay = end.toLocaleDateString('en-us', { day: 'numeric' })
  const endYear = end.toLocaleDateString('en-us', { year: 'numeric' })
  const sameMonth = startMonth === endMonth
  const sameYear = startYear === endYear

  return (
    <span>
      {/* eslint-disable-next-line max-len */}
      {startMonth} {startDay} {!sameYear && startYear} - {endDay} {!sameMonth && endMonth}, {endYear}
    </span>
  )
}

DateRange.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
}

export default DateRange

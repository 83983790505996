import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Image from '@components/atoms/image'

export const useImageStyles = makeStyles(theme => ({
  roundedImage: {
    width: '100px',
    height: '100px',
    position: 'relative',
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center',
    flexShrink: 0,
    borderRadius: '50%',
    justifyContent: 'center',

    '&:before': {
      content: '""',
      position: 'absolute',
      zIndex: 1,
      top: '5px',
      left: '5px',
      right: '5px',
      bottom: '5px',
      border: `2px solid ${theme.palette.primary.light}`,
      borderRadius: '50%',
    },
  },
}))

const RoundedImage = ({ altText, imageUrl, customClassName }) => {
  const s = useImageStyles()

  return (
    <div className={`${s.roundedImage} ${customClassName}`}>
      <Image src={imageUrl} alt={altText} />
    </div>
  )
}

RoundedImage.propTypes = {
  imageUrl: PropTypes.string,
  altText: PropTypes.string,
  customClassName: PropTypes.string,
}

RoundedImage.defaultProps = {
  altText: 'PGA Image',
}
export default RoundedImage

import { useState, useEffect } from 'react'
import Cookie from 'js-cookie'

const useCookie = (key, opts = {}) => {
  const [cookieValue, setCookieValue] = useState(Cookie.get(key))

  useEffect(() => {
    const val = Cookie.get(key)
    setCookieValue(val)
  }, [cookieValue])

  const setCookie = (value) => {
    Cookie.set(key, value, opts)
    setCookieValue(value)
  }

  return [cookieValue, setCookie]
}

export default useCookie

import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'

export const useImageStyles = makeStyles(theme => ({
  image: (image) => {
    const imageSm = image && image.sm ? image.sm : image.lg
    return ({
      backgroundImage: image && `${theme.palette.background.gradient}, url(${imageSm}?fl=progressive&q=80)`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      height: '100%',
      width: '100%',

      [theme.breakpoints.up('sm')]: {
        backgroundImage: image && `${theme.palette.background.gradient}, url(${image.lg}?fl=progressive&q=80)`,
      },
    })
  },
}))

const ImageAsBg = ({ image }) => {
  const stylesImage = useImageStyles(image)

  return (
    <Box className={stylesImage.image} />
  )
}

ImageAsBg.propTypes = {
  image: PropTypes.object,
}

export default ImageAsBg

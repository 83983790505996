import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.typography.overline,
    fontSize: '.75rem',
    fontWeight: 400,
    lineHeight: 0,
    display: 'flex',
    alignItems: 'center',
    marginLeft: '1.5625rem',
    color: theme.palette.common.black,
    '&:hover,&:active,&:focus': {
      color: theme.palette.common.black,
      textDecoration: 'underline',
      textUnderlineOffset: '3px',
    },
  },
  thumbnail: {
    marginRight: '.5rem',
  },
}))

export default useStyles

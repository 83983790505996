import PropTypes from 'prop-types'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { CtflTableRoot, Headline } from './ctfl-table.styles'


const CtflTable = (props) => {
  const {
    headline,
    rowsCollection,
    autoNumber,
    alignCol1,
    alignCol2,
    alignCol3,
    alignCol4,
    alignCol5,
  } = props

  return (
    <CtflTableRoot border={headline}>
      {headline && <Headline variant="h5">{headline}</Headline>}
      <Table>
        <TableBody>
          {rowsCollection && rowsCollection.items.map((row, index) => (
            <TableRow key={row.sys.id}>
              {autoNumber
                && <TableCell width={20}>{index + 1}</TableCell>
              }

              {row.column1Rich
                && (
                  <TableCell align={alignCol1 || 'left'}>
                    {documentToReactComponents(row.column1Rich.json)}
                  </TableCell>
                )
              }
              {row.column2Rich
                && (
                  <TableCell align={alignCol2 || 'left'}>
                  {documentToReactComponents(row.column2Rich.json)}
                  </TableCell>
                )
              }
              {row.column3Rich
                && (
                  <TableCell align={alignCol3 || 'left'}>
                  {documentToReactComponents(row.column3Rich.json)}
                  </TableCell>
                )
              }
              {row.column4Rich
                && (
                  <TableCell align={alignCol4 || 'left'}>
                  {documentToReactComponents(row.column4Rich.json)}
                  </TableCell>
                )
              }
              {row.column5Rich
                && (
                  <TableCell align={alignCol5 || 'left'}>
                  {documentToReactComponents(row.column5Rich.json)}
                  </TableCell>
                )
              }

            </TableRow>
          ))}
        </TableBody>
      </Table>
    </CtflTableRoot>
  )
}

CtflTable.propTypes = {
  headline: PropTypes.string,
  rowsCollection: PropTypes.shape(),
  autoNumber: PropTypes.bool,
  alignCol1: PropTypes.string,
  alignCol2: PropTypes.string,
  alignCol3: PropTypes.string,
  alignCol4: PropTypes.string,
  alignCol5: PropTypes.string,
}

CtflTable.defaultProps = {
  autoNumber: false,
}

export default CtflTable

import { styled } from '@material-ui/core/styles'

const Series = styled('h6')(({ theme }) => ({
  fontFamily: 'Roboto Condensed',
  fontWeight: 'bold',
  color: theme.palette.primary.dark,
  fontSize: '12px',
  letterSpacing: 1.5,
  lineHeight: '16px',
  textTransform: 'uppercase',
  margin: 0,
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
}))

export default Series

import PropTypes from 'prop-types'
import HorizontalMenuHeader from './horizontal-menu/header'

const Header = (props) => {
  return HorizontalMenuHeader(props)
}

Header.propTypes = {
  image: PropTypes.shape({
    url: PropTypes.string,
  }),
  linksCollection: PropTypes.shape({
    items: PropTypes.array,
  }),
  isHome: PropTypes.bool,
  hasLiveBanner: PropTypes.bool,
}

export default Header

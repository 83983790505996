import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const useArticleHeroRootStyles = makeStyles({
  root: props => ({
    maxWidth: `${props.maxwidth}px`,
    margin: '0 auto',
  }),
})

export const ArticleHeroRoot = (props) => {
  const { children } = props
  const classes = useArticleHeroRootStyles(props)
  return (
    <div className={`${classes.root}`}>
      {children}
    </div>
  )
}

ArticleHeroRoot.propTypes = {
  children: PropTypes.node,
}

export const useArchiveImageStyles = makeStyles({
  root: () => ({
    display: 'block',
    margin: '0 auto',
    maxWidth: '100%',
  }),
})

import SvgIcon from '@material-ui/core/SvgIcon'

const QuoteIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg><path d="M10.71 11.33a6.87 6.87 0 0 1 5.85 7.14c0 4.56-3.47 7.53-7.93 7.53C3.27 26 0 21.74 0 15.69c0-9 6-15 16.86-15.67v3.27c-5.95.8-9.52 3.28-10 8zm19.14 0c3.37.69 6 3 6 7.14 0 4.56-3.47 7.53-7.93 7.53-5.36 0-8.63-4.26-8.63-10.31 0-9 5.85-15 16.76-15.67v3.27c-5.95.8-9.42 3.28-9.92 8z" fill="#836f51" /></svg>
    </SvgIcon>
  )
}

export default QuoteIcon

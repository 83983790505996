import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: ({ hasHero }) => ({
    background: theme.palette.secondary.light,
    color: '#FFFFFF',
    marginTop: hasHero ? theme.spacing(9) : 0,
    [theme.breakpoints.down('sm')]: {
      marginTop: hasHero ? theme.spacing(7) : 0,
    },
  }),
  container: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      paddingTop: theme.spacing(2),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(1),
    },
    '& p': {
      lineHeight: '19px',
      [theme.breakpoints.down('sm')]: {
        width: '70%',
      },
      [theme.breakpoints.down('xs')]: {
        width: '90%',
      },
    },
  },
  actionWrapper: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-end',
      width: '100%',
    },
  },
  action: {
    background: '#FFFFFF',
    '&.MuiButton-root': {
      color: theme.palette.secondary.light,
      borderRadius: '4px',
      padding: '0 24px 0 24px',
      letterSpacing: '0.5px',
    },
  },
  actionHide: {
    marginRight: theme.spacing(4),
    '&.MuiButton-root': {
      color: '#FFFFFF',
      letterSpacing: '0.5px',
    },
  },
}))

export default useStyles

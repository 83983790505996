import PropTypes from 'prop-types'
import PrintProps from '@components/diag/printprops'
import ErrorStatusCode from '@components/error/statusCodeError'
import { ErrorRoot } from './error.styles'

const { ENV } = process.env

const Error = (props) => {
  const {
    type, id, error, statusCode, displayErrorType,
  } = props

  const logError = () => {
    // eslint-disable-next-line no-console
    console.error(error)
    if (window.rg4js != null) window.rg4js('send', { error })
  }

  const errorComponent = () => {
    logError()
    return (ENV === 'development' ? (
      <ErrorRoot>
        {type && <div>type: {type}</div>}
        {id && <div>id: {id}</div>}
        {error && <PrintProps {...error} />}
      </ErrorRoot>
    ) : null)
  }

  const errorPage = () => {
    logError()
    return (ENV === 'development' ? (
      <ErrorRoot>
        {type && <div>type: {type}</div>}
        {id && <div>id: {id}</div>}
        {error && <PrintProps {...error} />}
      </ErrorRoot>
    ) : <ErrorStatusCode statusCode={500} />)
  }

  const renderError = () => {
    return displayErrorType === 'page' ? errorPage() : errorComponent()
  }

  return (statusCode ? <ErrorStatusCode statusCode={statusCode} /> : renderError())
}

Error.propTypes = {
  /** In renderer, the Type (in Contentful) of embed */
  type: PropTypes.string,
  /** In renderer, the ID of the requested Type */
  id: PropTypes.string,
  /** Error from Apollo response */
  error: PropTypes.shape({}),
  statusCode: PropTypes.oneOf([403, 404, 500]),
  displayErrorType: PropTypes.oneOf(['page', 'component']),
}

Error.defaultProps = {}

export default Error

import gql from 'graphql-tag'
import { Query } from '@apollo/client/react/components'
import Error from '@components/error'
import LinearProgress from '@material-ui/core/LinearProgress'

import CtflTable from './ctfl-table'

export const TableFragment = `
title
autoNumber
headline
alignCol1
alignCol2
alignCol3
alignCol4
alignCol5
rowsCollection {
  items {
    sys {
      id
    }
    column1Rich {
      json
    }
    column2Rich {
      json
    }
    column3Rich {
      json
    }
    column4Rich {
      json
    }
    column5Rich {
      json
    }
  }
}
`

export const TableQuery = gql`
query Table($id: String!) {
  table(id: $id) {
    ${TableFragment}
  }
}
`

export default function CtflTableWithData(props) {
  return (
    <Query query={TableQuery} variables={props}>
      {({
        loading, error, data,
      }) => {
        if (error) return <Error error={error} />
        if (loading) return <LinearProgress color="secondary" />
        const response = data.table
        return (
          <CtflTable {...props} {...response} />
        )
      }}
    </Query>
  )
}

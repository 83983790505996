import gql from 'graphql-tag'
import { Query } from '@apollo/client/react/components'
import LinearProgress from '@material-ui/core/LinearProgress'
import Error from '@components/error'
import Footer from './footer'

const QUERY = gql`
query Footer {
  footerRefreshCollection(limit: 1) {
    items {
      copyright
      companyTitle,
      companyMission,
      partnerDescription,
      partnerLogo {
        url
      }
      linksCollection {
        items {
          headline
          linksCollection {
            items {
              __typename
              ... on Event {
                headline
                slug
              }
              ... on Story {
                headline
                slug
              }
              ... on NavLink {
                text
                link
                externalLink
              }
              ... on Tour {
                headline
                slug
              }
              ... on Page {
                headline
                slug
              }
            }
          }
        }
      }
      auxilliaryCollection {
       items {
          __typename
          ... on Page {
            headline
            slug
          }
          ... on NavLink {
            link,
            externalLink,
            text
          }
        }
      }
      socialCollection {
        items {
          title
          link
          headline
        }
      }
    }
  }
}
`

export default function FooterWithData(props) {
  return (
    <Query query={QUERY}>
      {({
        loading, error, data,
      }) => {
        if (error) return <Error error={error} />
        if (loading) return <LinearProgress color="secondary" />
        const response = data
          && data.footerRefreshCollection
          && data.footerRefreshCollection.items
          && data.footerRefreshCollection.items[0]

        return (
          <Footer {...props} {...response} />
        )
      }}
    </Query>
  )
}

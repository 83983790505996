const storyCardFragment = `
headline
author
slug
cat {
  category
  series
}
summary
sys {
  id
  publishedAt
  firstPublishedAt
}
image {
  description
  url
  width
  height
}
video {
  contentType
}
`

export default storyCardFragment

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.typography.navigation,
    backgroundColor: 'transparent',
    border: 0,
    cursor: 'pointer',
    position: 'relative',
    zIndex: 10,
    padding: 0,
    '&:hover,&:active,&:focus,&.open': {
      color: theme.palette.secondary.light,
      outline: 'none',
      '&:after': {
        content: '""',
        position: 'absolute',
        height: '5px',
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: 'currentColor',
      },
    },
  },
}))

export default useStyles

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    left: '-10000px',
    top: 'auto',
    width: '1px',
    height: '1px',
    overflow: 'hidden',
    padding: '4px',
    '&:focus': {
      position: 'static',
      width: 'auto',
      height: 'auto',
    },
  },
}))

export default useStyles

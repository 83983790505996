/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
// todo Please do not place new icons in this file. Because that increases the size of the bundle.
// todo Place them in the appropriate folder in src/components/atoms/icons.

import SvgIcon from '@material-ui/core/SvgIcon'
import React from 'react'

export const SITE_PRIMARY = '#836F50'
export const GOLDENROD = '#FFBE00'
export const BLACK = '#000'
const WHITE = '#FFF'
const BROWN = '#AA9056'

export const IconStar = props => (
  <svg
    width={50}
    height={50}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    alt=""
    {...props}
  >
    <circle cx={25} cy={25} r={25} fill="#E6BC5A" />
    <path
      d="M35.4999 21.8641L27.7963 21.1998L24.7856 14.1069L21.7749 21.2105L14.0713 21.8641L19.9213 26.9319L18.1641 34.4641L24.7856 30.4676L31.407 34.4641L29.6606 26.9319L35.4999 21.8641ZM24.7856 28.4641L20.757 30.8962L21.8284 26.3105L18.2713 23.2248L22.9641 22.8176L24.7856 18.4998L26.6177 22.8284L31.3106 23.2355L27.7534 26.3212L28.8249 30.9069L24.7856 28.4641Z"
      fill="#000"
    />
  </svg>
)

export const IconPermIdentity = props => (
  <SvgIcon {...props} viewBox="0 -14 35 70">
    <svg width="36" height="36">
      <g fill="none">
        <path
          d="M18 8.85a3.15 3.15 0 1 1 0 6.3 3.15 3.15 0 0 1 0-6.3Zm0 13.5c4.455 0 9.15 2.19 9.15 3.15v1.65H8.85V25.5c0-.96 4.695-3.15 9.15-3.15ZM18 6c-3.315 0-6 2.685-6 6s2.685 6 6 6 6-2.685 6-6-2.685-6-6-6Zm0 13.5c-4.005 0-12 2.01-12 6V30h24v-4.5c0-3.99-7.995-6-12-6Z"
          fill={props.fill || SITE_PRIMARY}
        />
      </g>
    </svg>
  </SvgIcon>
)

export const PlayButton = props => (
  <svg
    width={17}
    height={22}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M0 22V0l17 10.656L0 22Z" fill="#fff" />
  </svg>
)

export const PauseButton = props => (
  <svg
    width={13}
    height={22}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path fill="#fff" d="M0 0h3v22H0zM10 0h3v22h-3z" />
  </svg>
)

export const HamburgerMenuIcon = props => (
  <svg
    width={25}
    height={17}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path stroke="#001123" d="M0 8.5h25M0 16.5h25M0 .5h25" />
  </svg>
)

export const IconTikTokLogo = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        width=".9em"
        height=".9em"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 11 16"
      >
        <path
          d="M14 6.553c-1.406.031-2.719-.406-3.844-1.217v5.586c0 3.9-4.25 6.334-7.625 4.4-3.375-1.966-3.375-6.834 0-8.8a5.11 5.11 0 0 1 3.25-.624v2.808c-1.718-.561-3.375.968-3 2.715.406 1.748 2.531 2.434 3.875 1.217.469-.437.75-1.06.75-1.716V0h2.75c0 .25 0 .468.063.718a3.69 3.69 0 0 0 1.687 2.496c.594.406 1.344.624 2.094.624v2.715Z"
        />
      </svg>
    </SvgIcon>

  )
}

export const XIcon = props => (
  <SvgIcon {...props}>
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 1200 1227"
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        transform="translate(200, 240) scale(0.65)"
        d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z"
      />
    </svg>
  </SvgIcon>
)

export const IconConnect = props => (
  <svg
    width={40}
    height={40}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={20} cy={20} r={20} fill="#E6BC5A" />
    <path
      d="M19 22h-2a9 9 0 0 1 9-9v2c-3.87 0-7 3.13-7 7Zm7-3v-2c-2.76 0-5 2.24-5 5h2c0-1.66 1.34-3 3-3Zm-11-7c0-1.11-.89-2-2-2-1.11 0-2 .89-2 2 0 1.11.89 2 2 2 1.11 0 2-.89 2-2Zm4.45.5h-2A2.99 2.99 0 0 1 14.5 15h-3c-.83 0-1.5.67-1.5 1.5V19h6v-2.26a4.97 4.97 0 0 0 3.45-4.24ZM27 25c1.11 0 2-.89 2-2 0-1.11-.89-2-2-2-1.11 0-2 .89-2 2 0 1.11.89 2 2 2Zm1.5 1h-3a2.99 2.99 0 0 1-2.95-2.5h-2A4.97 4.97 0 0 0 24 27.74V30h6v-2.5c0-.83-.67-1.5-1.5-1.5Z"
      fill="#000"
    />
  </svg>
)

export const IconFlag = props => (
  <SvgIcon {...props}>
    <svg width="24" height="24">
      <g fill="none" fillRule="evenodd">
        <path
          d="M12.297 9.867V6.214l3.627 1.827-3.627 1.826zm-.57 8.619c-1.33 0-2.274-.177-2.767-.345.493-.167 1.437-.344 2.767-.344 1.33 0 2.274.177 2.768.344-.494.168-1.437.345-2.768.345zm5.726-10.959l-5.471-2.754a.568.568 0 0 0-.825.513V16.66c-1.305.049-3.647.3-3.647 1.48 0 1.385 3.228 1.492 4.217 1.492.99 0 4.218-.107 4.218-1.492 0-1.18-2.343-1.431-3.648-1.48V11.15l5.156-2.596a.574.574 0 0 0 0-1.027z"
          fill={props.fill || SITE_PRIMARY}
        />
      </g>
    </svg>
  </SvgIcon>
)

export const IconCalendar = () => (
  <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#a)">
      <path d="M12.667 2H12V.667h-1.333V2H5.333V.667H4V2h-.667C2.593 2 2 2.6 2 3.333v9.334C2 13.4 2.593 14 3.333 14h9.334C13.4 14 14 13.4 14 12.667V3.333C14 2.6 13.4 2 12.667 2Zm0 10.667H3.333V6h9.334v6.667Zm0-8H3.333V3.333h9.334v1.334Zm-8 2.666H8v3.334H4.667V7.333Z" fill="#836F50" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
)

export const IconFlagFilledOutlined = props => (
  <svg
    width={40}
    height={40}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={20} cy={20} r={20} fill="#E6BC5A" />
    <path
      d="m25.642 14.6-6.24-2.567a.394.394 0 0 0-.402.05c-.126.076-.176.202-.176.353v10.591c-2.214.1-3.824.956-3.824 2.063 0 1.183 1.811 2.063 4.226 2.063 2.416 0 4.227-.88 4.227-2.063 0-1.107-1.61-1.962-3.824-2.063v-5.912l5.937-1.736a.4.4 0 0 0 .302-.377c0-.177-.075-.353-.226-.403Zm-3.019 10.49c0 .503-1.308 1.208-3.396 1.208-2.063 0-3.397-.73-3.397-1.208 0-.453 1.132-1.107 2.969-1.208v1.636h.855v-1.636c1.837.101 2.969.755 2.969 1.208Z"
      fill="#00234B"
    />
  </svg>
)

export const IconPrice = props => (
  <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 7c0-.367.3-.667.667-.667.367 0 .667.3.667.667 0 .367-.3.667-.667.667A.669.669 0 0 1 10 7Zm-4.667-.667h3.334V5H5.334v1.333Zm9.334-1V9.98l-1.88.627-1.12 3.726H8V13H6.667v1.333H3s-1.667-5.64-1.667-7.667A3.665 3.665 0 0 1 5 3h3.334A3.33 3.33 0 0 1 11 1.667a.999.999 0 0 1 .92 1.386 3.22 3.22 0 0 0-.213.767l1.513 1.513h1.447Zm-1.333 1.333h-.667l-2.333-2.333c0-.433.06-.86.173-1.273a2.01 2.01 0 0 0-1.393 1.273H5a2.336 2.336 0 0 0-2.333 2.333c0 1.254.813 4.434 1.34 6.334h1.327v-1.334h4V13h1.34l1.033-3.434 1.627-.546V6.667Z" fill={props.fill || '#836F50'} /></svg>
)
export const IconPepsone = () => (
  <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#a)"><path d="M8 4c.733 0 1.333.6 1.333 1.333S8.733 6.666 8 6.666s-1.333-.6-1.333-1.333S7.267 4 8 4Zm0 6.666c1.8 0 3.867.86 4 1.334H4c.153-.48 2.207-1.334 4-1.334Zm0-8A2.666 2.666 0 1 0 8 8a2.666 2.666 0 1 0 0-5.333Zm0 6.667c-1.78 0-5.333.893-5.333 2.667v1.333h10.666V12c0-1.774-3.553-2.667-5.333-2.667Z" fill="#836F50" /></g><defs><clipPath id="a"><path fill="#fff" d="M0 0h16v16H0z" /></clipPath></defs></svg>
)

export const IconTimer = () => (
  <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#a)"><path d="M10.047.673h-4v1.334h4V.673ZM7.38 9.34h1.334v-4H7.38v4Zm5.353-4.413.947-.947c-.287-.34-.6-.66-.94-.94l-.947.947a5.974 5.974 0 0 0-3.746-1.32 6 6 0 1 0 6 6 6.003 6.003 0 0 0-1.314-3.74ZM8.047 13.34A4.663 4.663 0 0 1 3.38 8.673a4.663 4.663 0 0 1 4.667-4.666 4.663 4.663 0 0 1 4.666 4.666 4.663 4.663 0 0 1-4.666 4.667Z" fill="#836F50" /></g><defs><clipPath id="a"><path fill="#fff" d="M0 0h16v16H0z" /></clipPath></defs></svg>
)

export const IconTestimonialQuote = props => (
  <svg
    aria-hidden="true"
    width={50}
    height={41}
    fill="#E6BC5A"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 26.352C0 20.78 1.852 15.72 5.556 11.175 9.346 6.545 14.169 2.987 20.026.5l2.325 4.887c-2.067 1.2-4.177 2.83-6.33 4.888-2.068 1.972-3.876 4.287-5.427 6.945l5.685 3.987c1.981 1.458 3.316 3.002 4.005 4.63a11.855 11.855 0 0 1 1.034 4.888c0 2.915-.99 5.273-2.972 7.074-1.98 1.8-4.306 2.701-6.976 2.701-1.895 0-3.532-.343-4.91-1.029-1.378-.772-2.584-1.586-3.618-2.444C.947 33.855 0 30.297 0 26.352Zm27.649 0c0-5.573 1.851-10.632 5.555-15.177 3.79-4.63 8.613-8.188 14.47-10.675L50 5.387c-2.067 1.2-4.177 2.83-6.33 4.888-2.068 1.972-3.877 4.287-5.427 6.945l5.685 3.987c1.98 1.458 3.316 3.002 4.005 4.63a11.855 11.855 0 0 1 1.033 4.888c0 2.915-.99 5.273-2.971 7.074-1.981 1.8-4.307 2.701-6.977 2.701-1.895 0-3.531-.343-4.91-1.029-1.378-.772-2.583-1.586-3.617-2.444-1.895-3.172-2.842-6.73-2.842-10.675Z"
    />
  </svg>
)

export const IconButtonCtaArrow = props => (
  <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M15.4531 6.89929H0.71875C0.511111 6.89929 0.33941 6.81361 0.203646 6.64225C0.067882 6.47089 0 6.25416 0 5.99208C0 5.73 0.067882 5.51327 0.203646 5.34191C0.33941 5.17055 0.511111 5.08487 0.71875 5.08487H15.4771L13.4167 2.48419C13.2889 2.32291 13.225 2.1213 13.225 1.87938C13.225 1.63746 13.2969 1.42577 13.4406 1.24433C13.5844 1.06289 13.7521 0.972168 13.9438 0.972168C14.1354 0.972168 14.3031 1.06289 14.4469 1.24433L17.7052 5.35703C17.7851 5.45783 17.841 5.55863 17.8729 5.65944C17.9049 5.76024 17.9208 5.87112 17.9208 5.99208C17.9208 6.11304 17.9049 6.22392 17.8729 6.32473C17.841 6.42553 17.7851 6.52633 17.7052 6.62713L14.4469 10.7398C14.2872 10.9414 14.1155 11.0372 13.9318 11.0271C13.7481 11.017 13.5844 10.9213 13.4406 10.7398C13.2969 10.5584 13.225 10.3417 13.225 10.0897C13.225 9.83766 13.2889 9.63101 13.4167 9.46973L15.4531 6.89929Z" fill="currentColor" />
  </svg>
)

export const IconButtonCtaArrowExternal = props => (
  <svg width={20} height={11} {...props}>
    <path d="M.83 5.531h17.562l-2.196-2.196.587-.587 3.198 3.198-3.198 3.198-.587-.586 2.196-2.197H.83v3.722h8.273V7.832h.829v3.08H0V.98h9.932v3.168h-.829V1.81H.83z" />
  </svg>
)

export const IconArrow = props => (
  <SvgIcon {...props}>
    <svg width="32" height="7">
      <g fill="none" fillRule="evenodd" stroke={props.stroke || BLACK}>
        <path strokeLinecap="square" d="M.583 3.5h25.009" />
        <path d="M23.042 0l3.5 3.5-3.5 3.5" />
      </g>
    </svg>
  </SvgIcon>
)

export const TimelineArrow = props => (
  <SvgIcon {...props}>
    <svg width="53" height="13" viewBox="0 0 53 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M-2.35168e-07 7.44501L-3.16908e-07 5.57501L48.8 5.57501L44.8 1.57501L46.15 0.175016L51.77 5.79501L52.42 6.49502L51.77 7.20501L46.15 12.825L44.8 11.425L48.8 7.42502L-2.35168e-07 7.44501Z" fill="#C5A561" />
    </svg>
  </SvgIcon>
)

export const IconArrowExternal = props => (
  <SvgIcon {...props}>
    <svg width="20" height="11">
      <path
        fillRule="nonzero"
        d="M.83 5.531h17.562l-2.196-2.196.587-.587 3.198 3.198-3.198 3.198-.587-.586 2.196-2.197H.83v3.722h8.273V7.832h.829v3.08H0V.98h9.932v3.168h-.829V1.81H.83z"
      />
    </svg>
  </SvgIcon>
)

export const IconArrowDown = props => (
  <SvgIcon {...props}>
    <svg
      x="0px"
      y="0px"
      viewBox="0 0 476.213 476.213"
    >
      <polygon points="347.5,324.393 253.353,418.541 253.353,0 223.353,0 223.353,419.033 128.713,324.393 107.5,345.607
        238.107,476.213 368.713,345.606 "
      />
    </svg>
  </SvgIcon>
)

export const IconLongDownArrow = () => {
  return (
    <svg width="16" height="62" viewBox="0 0 16 62" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.92896 0V60" stroke="white" strokeWidth="1.25" />
      <path d="M0.928932 53.071L8 60.1421L15.0711 53.071" stroke="white" strokeWidth="1.2225" />
    </svg>
  )
}

export const IconDate = props => (
  <SvgIcon {...props}>
    <svg width="24" height="24">
      <g fill="none" fillRule="evenodd">
        <path
          d="M8.041 8.825c.295 0 .533-.26.533-.58v-.234h7.218v.235c0 .319.24.579.534.579.294 0 .534-.26.534-.58v-.234h.773v1.607H6.713V8.011h.795v.235c0 .319.238.579.533.579zm-.601 8.717h9.465v-6.766H7.44v6.766zM18.167 6.853h-1.33v-.63c0-.319-.238-.578-.533-.578-.294 0-.533.259-.533.579v.63H8.574v-.63c0-.32-.238-.58-.533-.58-.295 0-.533.26-.533.58v.63h-1.33c-.294 0-.533.258-.533.578v2.765c0 .32.24.58.533.58h.194v7.345c0 .32.24.579.535.579h10.532c.294 0 .534-.26.534-.58v-7.345h.194c.294 0 .534-.259.534-.579V7.432c0-.32-.24-.579-.534-.579z"
          fill={props.fill || SITE_PRIMARY}
        />
      </g>
    </svg>
  </SvgIcon>
)

export const IconDateFilledOutlined = props => (
  <svg
    width={40}
    height={40}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={20} cy={20} r={20} fill="#E6BC5A" />
    <path
      d="M23.75 12.5a.752.752 0 0 0-.75.75V14h-6v-.75a.752.752 0 0 0-.75-.75.752.752 0 0 0-.75.75V14h-.75c-.832 0-1.492.675-1.492 1.5L13.25 26a1.5 1.5 0 0 0 1.5 1.5h10.5c.825 0 1.5-.675 1.5-1.5V15.5c0-.825-.675-1.5-1.5-1.5h-.75v-.75a.752.752 0 0 0-.75-.75Zm1.5 13.5h-10.5v-7.5h10.5V26Zm-6-5.25c0-.413.337-.75.75-.75s.75.337.75.75-.337.75-.75.75a.752.752 0 0 1-.75-.75Zm-3 0c0-.413.337-.75.75-.75s.75.337.75.75-.337.75-.75.75a.752.752 0 0 1-.75-.75Zm6 0c0-.413.337-.75.75-.75s.75.337.75.75-.337.75-.75.75a.752.752 0 0 1-.75-.75Zm-3 3c0-.413.337-.75.75-.75s.75.337.75.75-.337.75-.75.75a.752.752 0 0 1-.75-.75Zm-3 0c0-.413.337-.75.75-.75s.75.337.75.75-.337.75-.75.75a.752.752 0 0 1-.75-.75Zm6 0c0-.413.337-.75.75-.75s.75.337.75.75-.337.75-.75.75a.752.752 0 0 1-.75-.75Z"
      fill="#00234B"
    />
  </svg>
)

export const IconLive = props => (
  <SvgIcon {...props}>
    <svg width="25" height="25">
      <g fill="none" fillRule="evenodd" transform="translate(.75 .75)">
        <circle cx="12" cy="12" r="12" fill={props.fill || GOLDENROD} fillRule="nonzero" />
        <path
          d="M17.316 6.863c2.648 2.867 2.648 7.548-.015 10.43a.545.545 0 0 1-.43.202.6.6 0 0 1-.429-.202.703.703 0 0 1 0-.945c2.176-2.356 2.176-6.184 0-8.54a.703.703 0 0 1 0-.945.581.581 0 0 1 .874 0zM15.07 9.176c1.582 1.6 1.596 4.203-.015 5.804a.58.58 0 0 1-.443.194.637.637 0 0 1-.444-.194.646.646 0 0 1 0-.913c1.094-1.091 1.08-2.886 0-3.978a.646.646 0 0 1 0-.913.628.628 0 0 1 .902 0zM7.718 7.81c-2.175 2.359-2.175 6.193-.015 8.536a.706.706 0 0 1 0 .947.545.545 0 0 1-.429.202.598.598 0 0 1-.43-.202c-2.646-2.871-2.646-7.559 0-10.43a.58.58 0 0 1 .874 0 .706.706 0 0 1 0 .947zm2.273 2.279a2.86 2.86 0 0 0-.015 3.978.646.646 0 0 1 0 .913.581.581 0 0 1-.444.194.639.639 0 0 1-.444-.194 4.137 4.137 0 0 1 0-5.804.63.63 0 0 1 .903 0 .646.646 0 0 1 0 .913zm2.089.444c.63 0 1.14.52 1.14 1.16 0 .641-.51 1.16-1.14 1.16-.63 0-1.14-.519-1.14-1.16 0-.64.51-1.16 1.14-1.16z"
          fill={props.stroke || BLACK}
        />
      </g>
    </svg>
  </SvgIcon>
)

export const IconMarker = props => (
  <SvgIcon {...props}>
    <svg width="24" height="24">
      <g fill="none" fillRule="evenodd">
        <path
          d="M12.173 4.712c1.484 0 2.907.573 3.957 1.592a5.354 5.354 0 0 1 1.638 3.843 5.532 5.532 0 0 1-.886 2.944c-1.121 1.802-4.14 6.131-4.269 6.314a.528.528 0 0 1-.437.228.529.529 0 0 1-.437-.228c-.128-.183-3.147-4.518-4.268-6.314a5.54 5.54 0 0 1-.894-2.946c0-1.44.59-2.822 1.639-3.842a5.683 5.683 0 0 1 3.957-1.59zm3.805 7.853a4.558 4.558 0 0 0 .741-2.418c0-1.577-.867-3.035-2.273-3.823a4.664 4.664 0 0 0-4.546 0c-1.407.788-2.274 2.246-2.274 3.823.013.856.27 1.693.742 2.418.857 1.374 2.906 4.348 3.805 5.643.899-1.295 2.948-4.267 3.805-5.643zM12.173 7.51a2.798 2.798 0 1 1-.002 5.597 2.798 2.798 0 0 1 .002-5.597zm0 4.546a1.749 1.749 0 1 0 0-3.497 1.749 1.749 0 0 0 0 3.497z"
          fillRule="nonzero"
          fill={props.fill || SITE_PRIMARY}
        />
      </g>
    </svg>
  </SvgIcon>
)

export const IconMarkerNew = props => (
  <SvgIcon {...props}>
    <svg
      width={8}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4 5.076a1.416 1.416 0 0 0 1.017-.427 1.459 1.459 0 0 0 .42-1.033 1.476 1.476 0 0 0-.42-1.032A1.434 1.434 0 0 0 4 2.157a1.416 1.416 0 0 0-1.017.427 1.459 1.459 0 0 0-.42 1.032 1.476 1.476 0 0 0 .42 1.033A1.434 1.434 0 0 0 4 5.076Zm0 4.06a14.861 14.861 0 0 0 2.457-2.791c.529-.82.793-1.548.793-2.183a3.344 3.344 0 0 0-.222-1.324 3.305 3.305 0 0 0-.723-1.125c-.3-.31-.66-.554-1.056-.717A3.103 3.103 0 0 0 4 .76a3.11 3.11 0 0 0-1.25.235 3.15 3.15 0 0 0-1.058.717 3.345 3.345 0 0 0-.942 2.45c0 .634.27 1.368.813 2.202A16.134 16.134 0 0 0 4 9.15v-.012Zm.013.851a.46.46 0 0 1-.3-.102 16.154 16.154 0 0 1-2.788-3.07C.308 5.868 0 4.984 0 4.161c0-1.269.402-2.28 1.205-3.033A4.032 4.032 0 0 1 3.999 0c1.039 0 2.039.404 2.794 1.13C7.597 1.883 8 2.894 8 4.161c0 .82-.308 1.703-.925 2.652a16.155 16.155 0 0 1-2.788 3.084.498.498 0 0 1-.132.076.385.385 0 0 1-.143.026v-.013Z"
        fill="#3268A7"
      />
    </svg>
  </SvgIcon>
)

export const IconMarkerOutlinePlusFill = props => (
  <svg
    width={40}
    height={40}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={20} cy={20} r={20} fill="#E6BC5A" />
    <path
      d="M20 20c-.825 0-1.5-.675-1.5-1.5S19.175 17 20 17s1.5.675 1.5 1.5S20.825 20 20 20Zm4.5-1.35c0-2.722-1.988-4.65-4.5-4.65s-4.5 1.928-4.5 4.65c0 1.755 1.462 4.08 4.5 6.855 3.038-2.775 4.5-5.1 4.5-6.855ZM20 12.5c3.15 0 6 2.415 6 6.15 0 2.49-2.003 5.438-6 8.85-3.997-3.413-6-6.36-6-8.85 0-3.735 2.85-6.15 6-6.15Z"
      fill="#00234B"
    />
  </svg>
)

export const IconMarkerFilled = props => (
  <svg width="24" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 0C3.13 0 0 3.13 0 7C0 12.25 7 20 7 20C7 20 14 12.25 14 7C14 3.13 10.87 0 7 0ZM7 9.5C5.62 9.5 4.5 8.38 4.5 7C4.5 5.62 5.62 4.5 7 4.5C8.38 4.5 9.5 5.62 9.5 7C9.5 8.38 8.38 9.5 7 9.5Z"
      fill={props.fill || SITE_PRIMARY}
    />
  </svg>
)

export const IconMarkerOutlined = props => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 2C8.13 2 5 5.13 5 9C5 14.25 12 22 12 22C12 22 19 14.25 19 9C19 5.13 15.87 2 12 2ZM7 9C7 6.24 9.24 4 12 4C14.76 4 17 6.24 17 9C17 11.88 14.12 16.19 12 18.88C9.92 16.21 7 11.85 7 9Z"
      fill={props.fill || SITE_PRIMARY}
    />
    <path
      d="M12 11.5C13.3807 11.5 14.5 10.3807 14.5 9C14.5 7.61929 13.3807 6.5 12 6.5C10.6193 6.5 9.5 7.61929 9.5 9C9.5 10.3807 10.6193 11.5 12 11.5Z"
      fill={props.fill || SITE_PRIMARY}
    />
  </svg>
)

export const IconClose = props => (
  <svg
    width={18}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 18"
    {...props}
  >
    <path d="M17 1L1 17" stroke="black" />
    <path d="M1 1L17 17" stroke="black" />
  </svg>
)

export const IconMarkerWithArea = props => (
  <SvgIcon {...props}>
    <svg width="24" height="24">
      <g fill="none" strokeWidth="0.25" stroke={props.stroke || SITE_PRIMARY}>
        <circle fill="#f5f5f5" stroke="none" cx="12" cy="12" r="12" />
        <circle cx="12.15" cy="9.5" r="1.5" />
        <path
          d="M13.8683+14.1357C15.2214+14.3879+16.156+14.9261+16.156+15.5493C16.156+16.4143+14.355+17.1156+12.1333+17.1156C9.91167+17.1156+8.11065+16.4143+8.11065+15.5493C8.11065+14.9536+8.96456+14.4356+10.2217+14.1707"
        />
        <path
          d="M9.60309+10.6544C9.48353+10.3416+9.4179+10.0011+9.4179+9.64498C9.4179+8.11431+10.6301+6.87346+12.1254+6.87346C13.6207+6.87346+14.8329+8.11431+14.8329+9.64498C14.8329+10.0168+14.7614+10.3715+14.6317+10.6955C14.2273+11.7053+12.176+15.0301+12.176+15.0301C12.176+15.0301+9.99734+11.6859+9.60309+10.6544Z"
        />
      </g>
    </svg>
  </SvgIcon>
)

export const IconNext = props => (
  <SvgIcon {...props}>
    <svg width="24" height="24">
      <defs>
        <path
          d="M12.835 13.363a1.332 1.332 0 0 1-2.352-.86c0-.501.277-.939.687-1.167V7.614h1.091v3.631c.45.16.788.552.87 1.032l3.76 2.1-.552.943-3.504-1.957zm4.3-5.629a6.622 6.622 0 0 0-3.35-2.115 6.62 6.62 0 0 0-5.06.666 6.674 6.674 0 0 0-2.44 9.107 6.617 6.617 0 0 0 4.049 3.105 6.611 6.611 0 0 0 5.058-.665l.627-.366.448.767-.628.367a7.51 7.51 0 0 1-5.735.756 7.508 7.508 0 0 1-4.589-3.52 7.51 7.51 0 0 1-.755-5.733 7.509 7.509 0 0 1 3.521-4.587 7.505 7.505 0 0 1 5.734-.755 7.496 7.496 0 0 1 3.952 2.585l1.703-.794-1.216 3.34-3.34-1.216 2.021-.942z"
          id="a-next"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <circle cx="12" cy="12" r="12" fill={props.fill || GOLDENROD} fillRule="nonzero" />
        <use fill={props.stroke || BLACK} href="#a-next" />
      </g>
    </svg>
  </SvgIcon>
)

export const IconClubs = props => (
  <SvgIcon {...props}>
    <svg width="24" height="24" viewBox="0 0 54 63">
      <path
        d="M26.98 62.22C12.08 62.22-.04 49.9-.04 34.75V4.52l1.19-.39c16.9-5.57 34.77-5.57 51.67 0l1.18.39v30.24c0 15.14-12.12 27.46-27.02 27.46zM3.41 7.03v27.73c0 13.24 10.57 24.02 23.57 24.02S50.55 48 50.55 34.76V7.03c-15.45-4.84-31.69-4.84-47.14 0z"
        fill={props.fill || BROWN}
      />
      <path
        d="M44.54 36.36l-.9-2.04c-.33-.74-.95-1.3-1.71-1.53-.77-.23-1.59-.12-2.26.31l-6.99 4.5-4.5-9.62 6.55-14.01-.96-.46-.97-.47-5.8 12.4-5.8-12.41-1.93.93 6.55 14.01-4.5 9.62-6.99-4.5a2.7 2.7 0 00-2.26-.32c-.76.23-1.39.79-1.71 1.53l-.9 2.05c-.61 1.38-.01 3.03 1.34 3.67l5.2 2.5c.67.32 1.37.47 2.06.47 1.83 0 3.59-1.05 4.43-2.84L27 30.51l4.51 9.64c.84 1.79 2.6 2.84 4.43 2.84.69 0 1.4-.15 2.06-.47l5.2-2.48c1.35-.65 1.95-2.3 1.34-3.68"
        fillRule="evenodd"
        clipRule="evenodd"
        fill={props.fill || BROWN}
      />
    </svg>
  </SvgIcon>
)

export const IconPlayVideo = props => (
  <SvgIcon {...props}>
    <svg width="24" height="24" viewBox="0 0 72 72">
      <title>video icon</title>
      <defs>
        <path
          d="M25.384 13.939L10.812 4.22c-1.296-.864-3.034.062-3.034 1.617v19.436c0 1.555 1.738 2.48 3.034 1.617l14.572-9.72a1.941 1.941 0 000-3.231"
          id="prefix__a"
        />
      </defs>
      <g transform="translate(1 1)" fill="none" fillRule="evenodd">
        <circle
          stroke={props.stroke || WHITE}
          strokeWidth={2}
          opacity={0.8}
          cx={35}
          cy={35}
          r={35}
        />
        <use
          fill={props.fill || WHITE}
          xlinkHref="#prefix__a"
          transform="translate(21 19.444)"
        />
      </g>
    </svg>
  </SvgIcon>
)

// --------------------------
// Coaches Page Icons
// --------------------------
export const IconCheckMark = props => (
  <SvgIcon {...props}>
    <svg viewBox="0 0 24 24">
      <polygon
        points="18.72 7.93 17.66 6.87 9.53 15.01 6.34 11.82 5.28 12.88 8.46 16.07 8.46 16.07 9.53 17.13 10.59 16.07 10.59 16.07 18.72 7.93"
        fill={props.fill || SITE_PRIMARY}
      />
    </svg>
  </SvgIcon>
)

export const IconBigMinus = () => {
  return (
    <svg width="16" height="4" viewBox="0 0 16 4" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="3.23083" width="2.46154" height="16" transform="rotate(-90 0 3.23083)" fill="#043362" />
    </svg>
  )
}

export const IconBigPlus = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="6.76923" width="2.46154" height="16" fill="#043362" />
      <rect y="9.23077" width="2.46154" height="16" transform="rotate(-90 0 9.23077)" fill="#043362" />
    </svg>
  )
}

export const GoldLongRightArrow = () => {
  return (
    <svg width="600" height="10" viewBox="0 0 600 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M595.387 0L599.522 4.44272L600 5.00396L599.522 5.55732L595.387 10L594.393 8.89326L597.336 5.73121H0V4.25296H597.381L594.437 1.09091L595.387 0Z" fill="#E6BC5A" />
    </svg>
  )
}

export const BlueLongRightArrow = (props) => {
  return (
    <svg height="10" viewBox="0 0 560 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M554.725 -6.10352e-05L559.453 4.44266L560 5.0039L559.453 5.55726L554.725 9.99994L553.589 8.8932L556.955 5.73115L0 5.73121V4.25296L557.005 4.2529L553.64 1.09085L554.725 -6.10352e-05Z" fill="#043362" />
    </svg>
  )
}

export const IconFilledStar = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.8652 4.40878L8.76576 7.21971L9.64008 11.3159L6.00291 9.24375L2.36576 11.3159L3.24008 7.21971L0.140625 4.42626L4.28925 3.9891L6.00729 0.146484L7.72095 3.9891L11.8652 4.40878Z" fill="black" />
    </svg>
  )
}

export const IconBulletedList = () => {
  return (
    <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 5.83331H1.66667V4.16665H0V5.83331ZM0 9.16665H1.66667V7.49998H0V9.16665ZM0 2.49998H1.66667V0.833313H0V2.49998ZM3.33333 5.83331H15V4.16665H3.33333V5.83331ZM3.33333 9.16665H15V7.49998H3.33333V9.16665ZM3.33333 0.833313V2.49998H15V0.833313H3.33333ZM0 5.83331H1.66667V4.16665H0V5.83331ZM0 9.16665H1.66667V7.49998H0V9.16665ZM0 2.49998H1.66667V0.833313H0V2.49998ZM3.33333 5.83331H15V4.16665H3.33333V5.83331ZM3.33333 9.16665H15V7.49998H3.33333V9.16665ZM3.33333 0.833313V2.49998H15V0.833313H3.33333Z" fill="white" />
    </svg>
  )
}

export const IconClock = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.1932 0.333008C3.5132 0.333008 0.533203 3.31967 0.533203 6.99968C0.533203 10.6797 3.5132 13.6663 7.1932 13.6663C10.8799 13.6663 13.8665 10.6797 13.8665 6.99968C13.8665 3.31967 10.8799 0.333008 7.1932 0.333008ZM7.19987 12.333C4.2532 12.333 1.86654 9.94634 1.86654 6.99968C1.86654 4.05301 4.2532 1.66634 7.19987 1.66634C10.1465 1.66634 12.5332 4.05301 12.5332 6.99968C12.5332 9.94634 10.1465 12.333 7.19987 12.333ZM7.5332 3.66634H6.5332V7.66634L10.0332 9.76634L10.5332 8.94634L7.5332 7.16634V3.66634Z" fill="#6A6A6A" />
    </svg>

  )
}

export const IconGoldPlay = () => {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.666748 0L14.2779 7L0.666748 15V0Z" fill="#AB9157" />
    </svg>
  )
}

export const IconGoldPlaySmall = () => {
  return (
    <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0.857178L9.33333 5.65718L0 11.1429V0.857178Z" fill="#AB9157" />
    </svg>
  )
}

export const IconGoldPauseSmall = () => {
  return (
    <svg width="9" height="11" viewBox="0 0 9 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.000244141" y="0.466553" width="3.2" height="9.6" fill="#AB9157" />
      <rect x="5.33325" y="0.466553" width="3.2" height="9.6" fill="#AB9157" />
    </svg>
  )
}

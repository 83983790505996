import { useMemo } from 'react'

export function useFeaturedStories(link) {
  const hasFeaturedStories = link.featuredStoriesCollection
    && !!link.featuredStoriesCollection?.items?.length
  const hasFeaturedStoryCategories = link.featuredStoryCategoriesCollection
    && !!link.featuredStoryCategoriesCollection?.items?.length

  const showFeaturedStoriesByCategory = (!hasFeaturedStories
    || link.featuredStoriesCollection.items.length === 1)
      && hasFeaturedStoryCategories

  const featuredStoryCategoryIds = useMemo(() => {
    return hasFeaturedStoryCategories
      ? link.featuredStoryCategoriesCollection.items.reduce((agg, item) => {
        agg.push(item.sys.id)
        return agg
      }, [])
      : []
  }, [hasFeaturedStoryCategories])

  const featuredStoryQueryLimit = useMemo(() => {
    if (!hasFeaturedStories) return 2
    return 1
  }, [hasFeaturedStories])

  return {
    hasFeaturedStories,
    showFeaturedStoriesByCategory,
    featuredStoryCategoryIds,
    featuredStoryQueryLimit,
  }
}

import { makeStyles } from '@material-ui/core/styles'

const navWidth = 240

const useStyles = makeStyles(theme => ({
  '@keyframes fadeIn': {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
  containerWrapper: {
    boxShadow: '0px 4px 36px rgba(0, 10, 22, 0.13)',
    zIndex: 10,
  },
  container: {
    height: '45px',
    minHeight: 'initial',
  },
  whiteNav: {
    background: 'white',
    boxShadow: '0px 10px 20px 0px rgba(0, 0, 0, .05)',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  transparentNav: {
    background: 'linear-gradient(180deg, rgba(0,0,0,0.45) 0%, rgba(0,0,0,0.2) 50%, rgba(0,0,0,0.1) 75%, rgba(0,0,0,0) 100%)',
    boxShadow: 'none',
    '& a, & button': {
      color: 'white',
    },
  },
  flyoutNav: {
    backgroundColor: 'transparent',
  },
  hamburger: {
    cursor: 'pointer',
    position: 'relative',
    color: theme.palette.common.black,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'transparent',
    border: 'none',
    '&:focus': {
      outline: 'none',
    },
  },
  close: {
    color: theme.palette.common.black,
    background: 'transparent',
    [theme.breakpoints.up('sm')]: {
      marginLeft: -8,
    },
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100vh',
    width: '100vw',
    zIndex: 1100,
    overflowX: 'hidden',
    overflowY: 'auto',
    backgroundColor: theme.palette.secondary.main,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    transition: `${theme.transitions.duration.enteringScreen}ms transform`,
    paddingTop: theme.spacing(6),
  },
  drawer: {
    '& .MuiDrawer-paper': {
      backgroundColor: theme.palette.secondary.main,
      backgroundSize: 'cover',
    },
  },
  searchButton: {
    height: '24px',
    width: '24px',
  },
  navWrap: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
  },
  nav: {
    paddingTop: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      width: '50%',
    },
  },
  navPrimary: {
    width: navWidth,
  },
  navLinkWrap: {
    position: 'relative',
    width: 180,
  },
  navLink: {
    display: 'inline-block',
    padding: `${theme.spacing(1)}px 0`,
    margin: `${theme.spacing(1)}px 0`,
    background: 'transparent',
    outline: 'none',
    border: 'none',
    boxSizing: 'border-box',
    opacity: 0.6,
    '&:hover': {
      opacity: 0.3,
      color: `${theme.palette.secondary.contrastText}`,
    },
    '&.is-selected': {
      opacity: 1,
    },
    '& svg': {
      position: 'relative',
      top: 2,
      [theme.breakpoints.only('xs')]: {
        top: 4,
      },
    },
  },
  subnavIcon: {
    position: 'relative',
    opacity: 0.7,
    top: -6,
    left: 8,
    zIndex: 1,
    '&:hover': {
      opacity: 1,
    },
    '&.is-selected': {
      opacity: 1,
    },
  },
  hideForSub: {
    [theme.breakpoints.only('xs')]: {
      display: 'none',
    },
  },
  subnav: {
    paddingTop: theme.spacing(12),
    paddingLeft: theme.spacing(3),
    animationName: '$fadeIn',
    animationDuration: theme.transitions.duration.enteringScreen,
    [theme.breakpoints.only('xs')]: {
      paddingTop: theme.spacing(2),
      width: navWidth,
      paddingLeft: 0,
    },
  },
  subnavArrow: {
    position: 'relative',
    left: theme.spacing(-2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  subnavGroup: {
    marginBottom: theme.spacing(4),
    '& a': {
      display: 'block',
      padding: '2px 0',
    },
  },
  subnavLink: {
    display: 'block',
  },
  subnavMainLink: {
    display: 'block',
    marginBottom: theme.spacing(3),
  },
  search: {
    color: 'white',
    borderBottom: '1px solid rgba(255, 255, 255, 0.5)',
    fontFamily: theme.typography.h1.fontFamily,
    fontSize: 24,
    '& button': {
      padding: 0,
    },
    '& .MuiInputBase-root': {
      color: 'white',
    },
  },
  helpText: {
    color: 'rgba(255, 255, 255, 0.5)',
    marginBottom: theme.spacing(3),
  },
}))

export default useStyles

import { useEffect, useState } from 'react'

const useExtractContentAndScripts = (htmlString) => {
  const [content, setContent] = useState('')
  const [scriptStrings, setScriptStrings] = useState([])

  useEffect(() => {
    const parser = new DOMParser()
    const doc = parser.parseFromString(htmlString, 'text/html')
    const scriptTags = Array.from(doc.getElementsByTagName('script'))

    setContent(doc.body.innerHTML)
    setScriptStrings(scriptTags.map(script => script.outerHTML))
  }, [htmlString])

  return { content, scriptStrings }
}

export default useExtractContentAndScripts

import Head from 'next/head'

const JsonLd = (props) => {
  const jsonld = {
    '@context': 'https://schema.org',
    ...props,
  }
  return (
    <Head>
      <script
        type="application/ld+json"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonld) }}
      />
    </Head>
  )
}

export default JsonLd

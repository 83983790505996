import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  srOnly: {
    position: 'absolute',
    left: '-10000px',
    top: 'auto',
    width: '1px',
    height: '1px',
    overflow: 'hidden',
  },
}))

const SrOnly = (props) => {
  const { children } = props

  const styles = useStyles()

  return (
    <span className={styles.srOnly}>
      {children}
    </span>
  )
}

SrOnly.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}

SrOnly.defaultProps = {
  children: '',
}

export default SrOnly

const metersPerMile = 1609
const defaults = {
  recommendedHeadline: 'We also recommend',
  maxBrowserCache: 600, // 10 minutes
  maxServerCache: 31536000, // 1 year
  pagination: 12,
  paginationArchive: 30,
  groupEventsPerPage: 12,
  headerHeight: 56,
  headerHeightDesktop: 64,
  redesignHeaderHeight: 45,
  redesignHeaderHeightDesktop: 90,
  facilityImage: 'https://images.ctfassets.net/56u5qdsjym8c/6kQFDyNM3Vb6eEbABGNFaf/a4c5170032ba4294784b9675c006edec/golf-grass-green-riot-2.jpg',
  coachPlaceholder: 'https://images.ctfassets.net/56u5qdsjym8c/2kB6wynjCzg3fifeWxzgux/0421b191fe198d2e4a6642e2803538d3/coach-placeholder-avatar-fpo.jpg',
  leaderboardPollInterval: 3 * 60 * 1000,
  matchPlayLeaderboardPollInterval: 60 * 1000,
  liveBannerPollInterval: 5 * 60 * 1000,
  userPositionLifetime: 180, // days
  baseUrl: 'https://www.pga.com/',
  coachBaseUrl: 'https://directory.pga.org/member/detail/',
  googleMapsSearchUrl: 'https://www.google.com/maps/search/?api=1&query=',
  coachesFilterWithPriority: 'bookable:true<score=2> OR leadResponsive:true<score=2> OR bookable:false<score=1> OR leadResponsive:false<score=1>',
  aroundPrecisionWithPriority: [
    { from: 0, value: 20 * metersPerMile },
    { from: 20 * metersPerMile, value: 50 * metersPerMile },
    { from: 50 * metersPerMile, value: 100 * metersPerMile },
    { from: 100 * metersPerMile, value: 200 * metersPerMile },
  ],
  coachAdCookieExp: 14, // days
  env: {
    dev: {},
    stage: {},
    prod: {},
  },
  noindexNofollow: process.env.ENV !== 'production',
}

export default defaults

import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import PropTypes from 'prop-types'
import HeaderSearchButton from '../common/header-search-button'
import UtilityNavLink from '../common/utility-nav-link/index'
import useStyles from './desktop-utility-nav.styles'

export default function DesktopUtilityNav(props) {
  const { utilityNavigationLinksCollection } = props
  const styles = useStyles()
  return (
    <Box className={styles.root}>
      <Container style={{ height: '100%' }}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          className={styles.container}
        >
          {utilityNavigationLinksCollection?.items?.map(link => (
            <UtilityNavLink key={link.sys.id} link={link} />
          ))}
          <HeaderSearchButton className={`${styles.link} ${styles.search}`} />
        </Box>
      </Container>
    </Box>
  )
}

DesktopUtilityNav.propTypes = {
  utilityNavigationLinksCollection: PropTypes.shape({
    items: PropTypes.array,
  }),
}
